import { Component, OnInit } from '@angular/core';
import { AppModel, RefreshFlags } from 'src/app/usecase/app-usecase.service';
import { InitialPositionLatLngDef, SheetEntity, SheetUsecase } from 'src/app/usecase/sheet-usecase.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef} from '@angular/material/dialog';
import { Title } from "@angular/platform-browser";
import { ConfirmModalService } from '../confirm-modal/confirm-modal.service';
import { ShareModalService } from '../../modal/share-modal/share-modal.service';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import 'moment/locale/ja';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-app-setting-modal',
  templateUrl: './app-setting-modal.component.html',
  styleUrls: ['./app-setting-modal.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AppSettingModalComponent {
  app: AppModel;
  sheet: SheetEntity;
  onDirty: (boolean)=>void;
  onMapPosition: ()=>InitialPositionLatLngDef;
  initGps: ()=>void;
  moveToAllMarker: ()=>void;
  imageUploadStart: ()=>void;
  imageUploadFinish: ()=>void;

  shareId: string;
  shareIdPublishEnable: boolean = true;
  shareIdErrorMessage: string;
  domain = '.' + environment.domain.user;

  reservedSaveCount: {reservedSaveTime: number};

  name: string;
  nameReadyToEdit: boolean = false;
  author: string;
  authorReadyToEdit: boolean = false;
  description: string;
  descriptionReadyToEdit: boolean = false;
  notification: string;
  notificationReadyToEdit: boolean = false;
  question: string;
  questionReadyToEdit: boolean = false;
  contact: string;
  contactReadyToEdit: boolean = false;
  questionValid: boolean = true;
  contactValid: boolean = true;
  deleteDate: any;
  streamValueDeleteDate: any;
  updateType: string;
  updateFrequency: string;
  showFrequency = false;
  // @ts-ignore
  minDate = moment();
  // @ts-ignore
  dateFormat = new FormControl(moment());
  // @ts-ignore
  defaultDeleteDate = null;
  defineDate = () => {
    const arr = [];
    for (let i = 1; i < 32; i++) {
      arr.push({label: i + '日', value: i.toString()});
    }
    return arr;
  }
  updateTypeRef = [
    {label: 'なし', value: ''},
    {label: '毎日', value: 'daily'},
    {label: '毎週', value: 'weekly'},
    {label: '毎月', value: 'monthly'},
  ];
  updateFrequencyRef = {
    weekly: [
      {label: '月曜日', value: '1'},
      {label: '火曜日', value: '2'},
      {label: '水曜日', value: '3'},
      {label: '木曜日', value: '4'},
      {label: '金曜日', value: '5'},
      {label: '土曜日', value: '6'},
      {label: '日曜日', value: '0'},
    ],
    monthly: this.defineDate()
  };

  ngOnInit(): void {
    // this.myFormGroup = new FormGroup({iconCss: this.iconCss});
    this.name = this.app.name;
    this.author = this.app.author;
    this.description = this.app.description;
    this.notification = this.app.notification;
    this.question = this.app.question;
    this.contact = this.app.contact;
    this.deleteDate = new Date(this.app.deleteDate);
    this.streamValueDeleteDate = this.app.deleteDate;
    this.updateType = this.app.updateType;
    this.updateFrequency = this.app.updateFrequency;
    this.showFrequency = (this.updateType === 'weekly' || this.updateType === 'monthly');
  }

  constructor(
    public activeModal: MatDialogRef<AppSettingModalComponent>,
    private titleService: Title,
    private confirmModal: ConfirmModalService,
    private shareModal: ShareModalService,
    private sheetUsecase: SheetUsecase,
  ) {
  }

  onCloseClick() {
    this.activeModal.close();
  }

  async onDeleteAppOuter() {
    if (await this.confirmModal.show('確認', '本当にこのアプリケーションを削除しますか？', 'はい', 'いいえ')) {
      this.activeModal.close({ appDelete: true });
      return;
    }
  }

  onRequestMapPosition(requester: (InitialPositionLatLngDef)=>void) {
    const pos = this.onMapPosition();
    requester(pos);
  }

  onImageChanged(data: string) {
    var imageRefreshFlags = {
      refreshMap: false,
      refreshManifest: false,
      changeShareId: false,
      oldShareId: this.app.shareId,
      imageSource: data,
      callback: null,
    } as RefreshFlags;
    this.onDirty(imageRefreshFlags);
  }

  onAppColorChanged() {
    var appColorRefreshFlags = {
      refreshMap: false,
      refreshManifest: true,
      changeShareId: false,
      oldShareId: this.app.shareId,
      imageSource: '',
      callback: null,
    }as RefreshFlags;
    this.onDirty(appColorRefreshFlags);
  }

  validateFieldUrl(field) {
    const urlRegex = /^https?:\/\/[\w!\?/\+\-_~=;:\.,\*&@#\$%\(\)'\[\]]+$/;
    if (field === 'question') {
      this.questionValid = urlRegex.test(this.question) || this.question === '';
    } else if (field === 'contact') {
      this.contactValid = urlRegex.test(this.contact) || this.contact === '';
    }
  }

  onTextDecided(event) {
    if (event.type !== 'keyup' || event.keyCode == 13) {
      if (this.app.name !== this.name) {
        this.app.name = this.name;
        var appNameRefreshFlags = {
          refreshMap: false,
          refreshManifest: true,
          nameChange: true,
          changeShareId: false,
          oldShareId: this.app.shareId,
          imageSource: '',
          callback: null,
        }as RefreshFlags;
        this.onDirty(appNameRefreshFlags);
        this.nameReadyToEdit = (event.type !== 'blur');
        this.titleService.setTitle(this.name);
      } else if (this.app.author !== this.author) {
        this.app.author = this.author;
        this.onDirty(false);
        this.authorReadyToEdit = (event.type !== 'blur');
      } else if (this.app.description !== this.description) {
        this.app.description = this.description;
        this.onDirty(false);
        this.descriptionReadyToEdit = (event.type !== 'blur');
      } else if (this.app.notification !== this.notification) {
        this.app.notification = this.notification;
        this.onDirty(false);
        this.notificationReadyToEdit = (event.type !== 'blur');
      } else if (this.app.question !== this.question && this.questionValid) {
        this.app.question = this.question;
        this.onDirty(false);
        this.questionReadyToEdit = (event.type !== 'blur');
      } else if (this.app.contact !== this.contact && this.contactValid) {
        this.app.contact = this.contact;
        this.onDirty(false);
        this.contactReadyToEdit = (event.type !== 'blur');
      }
    }
  }

  onTextFocus(text: 'name' | 'author' | 'description' | 'notification' | 'question' | 'contact') {
    if (text == 'name') this.nameReadyToEdit = true;
    else if (text == 'author') this.authorReadyToEdit = true;
    else if (text == 'notification') this.notificationReadyToEdit = true;
    else if (text == 'question') this.questionReadyToEdit = true;
    else if (text == 'contact') this.contactReadyToEdit = true;
    else this.descriptionReadyToEdit = true;
  }

  onTextEdit(text: 'name' | 'author' | 'description' | 'notification' | 'question' | 'contact') {
    if (text == 'name') {
      if (this.nameReadyToEdit && this.app.name !== this.name) {
        this.nameReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.nameReadyToEdit && this.app.name === this.name) {
        this.nameReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else if (text == 'author') {
      if (this.authorReadyToEdit && this.app.author !== this.author) {
        this.authorReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.authorReadyToEdit && this.app.author === this.author) {
        this.authorReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else if (text == 'notification') {
      if (this.notificationReadyToEdit && this.app.notification !== this.notification) {
        this.notificationReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.notificationReadyToEdit && this.app.notification === this.notification) {
        this.notificationReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else if (text == 'question') {
      this.validateFieldUrl('question');
      if (this.questionReadyToEdit && this.app.question !== this.question) {
        this.questionReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.questionReadyToEdit && this.app.question === this.question) {
        this.questionReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else if (text == 'contact') {
      this.validateFieldUrl('contact');
      if (this.contactReadyToEdit && this.app.contact !== this.contact) {
        this.contactReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.contactReadyToEdit && this.app.contact === this.contact) {
        this.contactReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else {
      if (this.descriptionReadyToEdit && this.app.description !== this.description) {
        this.descriptionReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.descriptionReadyToEdit && this.app.description === this.description) {
        this.descriptionReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    }
  }

  async onShareIdChanged() {
    if (this.shareId==this.app.shareId) {
      this.shareIdPublishEnable = true;
    } else if (this.shareId == '') {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = '公開URLを入力してください。';
    } else if (this.shareId.match(/[^a-z0-9\-]|--|^-/)!=null) {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = 'URLに使用できない文字が含まれます。';
    } else if (await this.sheetUsecase.checkShareIdName(this.shareId)) {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = 'このURLは使用済みです。';
    } else if (this.shareId==this.app.shareId) {
      this.shareIdPublishEnable = true;
    } else if (this.shareId == '') {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = '公開URLを入力してください。';
    } else if (this.shareId.match(/[^a-z0-9\-]|--|^-/)!=null) {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = 'URLに使用できない文字が含まれます。';
    } else {
      this.shareIdPublishEnable = true;
    }
  }

  async onShareIdPublished(event) {
    if (event.type !== 'keyup' || event.keyCode == 13) {
      await this.onShareIdChanged();
      if (this.shareIdPublishEnable) {
        if (this.shareId!=this.app.shareId) {
          var appNameRefreshFlags = {
            refreshMap: false,
            refreshManifest: true,
            changeShareId: true,
            oldShareId: this.app.shareId,
            imageSource: '',
            callback: null,
          }as RefreshFlags;
          this.app.shareId = this.shareId;
          this.onDirty(appNameRefreshFlags);
        }
      }
    }
  }

  async onShareModalShow() {
    await this.onShareIdChanged();
    if (this.shareIdPublishEnable) this.shareModal.show(this.shareId);
  }

  onTypeUpdateChange() {
    this.showFrequency = (this.updateType === 'weekly' || this.updateType === 'monthly');
    if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
    this.app.updateFrequency = this.updateFrequency = '1';
    this.app.updateType = this.updateType;
    this.onDirty(false);
  }

  onUpdateFrequencyChange() {
    if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
    this.app.updateFrequency = this.updateFrequency;
    this.onDirty(false);
  }

  onDeleteDateChange() {
    if (this.dateFormat.invalid) {
      this.deleteDate = this.defaultDeleteDate;
      this.dateFormat.setValue(this.defaultDeleteDate);
    }
    if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
    this.app.deleteDate = !this.deleteDate ? '' : this.deleteDate.format('YYYY/MM/DD');
    this.streamValueDeleteDate = this.app.deleteDate;
    this.onDirty(false);
  }

  onDateKeyup(event) {
    const regexDate = /^\d{0,4}$|^\d{0,4}\/0?$|^\d{1,4}\/(?:0?[1-9]|1[012])(?:\/(?:0?[1-9]?|[12]\d|3[01])?)?$/;
    const regexFullDate = /^\d{4}\/(0?[1-9]|1[012])(\/(0?[1-9]|[12]\d|3[01]))$/;
    if (regexDate.test(event.target.value)) {
      this.streamValueDeleteDate = event.target.value;
    } else {
      event.target.value = this.streamValueDeleteDate;
      if (regexFullDate.test(this.streamValueDeleteDate)) {
        // @ts-ignore
        this.deleteDate = moment(this.streamValueDeleteDate);
      }
    }
  }

  onClearDeleteDate() {
    this.streamValueDeleteDate = '';
    this.deleteDate = null;
    this.app.deleteDate = '';
    this.onDirty(false);
  }
}
