<div class="d-flex  flex-column">
  <app-mddb-select
    class="flex-fill"
    placeholder="並び順を選択"
    [outline]="true"
    [options]="types"
    [isRequired]="true"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)">
  </app-mddb-select>
</div>
