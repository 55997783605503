import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppThemeDef } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-fav',
  templateUrl: './fav.component.html',
  styleUrls: ['./fav.component.scss']
})
export class FavComponent implements OnInit, OnChanges {
  @Input() iconClass = 'fa-layer-group';
  @Input() position: 'bottomLeft' | 'bottomRight' | 'topRight' = 'bottomRight';
  @Input() offsetRight = 0;
  @Input() offsetLeft = 0;
  @Input() offsetTop = 0;
  @Input() offsetBottom = 0;
  @Output() readonly click = new EventEmitter<any>();

  positionTop = '-1';
  positionRight = '-1';
  positionBottom = '-1';
  positionLeft = '-1';

  private readonly offset = 20;

  @Input() theme: AppThemeDef;

  // @HostBinding("attr.style")
  // public get valueAsStyle(): any {
  //   let bkColor = 'grey';
  //   if (this.theme != null) {
  //     bkColor = this.theme.buttonPrimaryBackgroundColor;
  //   }

  //   let foreColor = 'white';
  //   if (this.theme != null) {
  //     foreColor = this.theme.buttonPrimaryForegroundColor;
  //   }
  //   return this.sanitizer.bypassSecurityTrustStyle(`--theme-bk-color: ${bkColor}; --theme-fore-color: ${foreColor};`);
  // }

  private readonly value = '#00FF00';

  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit() {
    this.updatePosition();
  }

  private updatePosition() {
    const value = this.position;
    if (value === 'bottomRight') {
      this.positionBottom = `${this.offset + this.offsetBottom}px`;
      this.positionRight = `${this.offset + this.offsetRight}px`;
    } else if (value === 'bottomLeft') {
      this.positionBottom = `${this.offset + this.offsetBottom}px`;
      this.positionLeft = `${this.offset + this.offsetLeft}px`;
    } else if (value === 'topRight') {
      this.positionTop = `${this.offset + this.offsetTop}px`;
      this.positionRight = `${this.offset + this.offsetRight}px`;
    } else if (value === 'topLeft') {
      this.positionTop = `${this.offset + this.offsetTop}px`;
      this.positionLeft = `${this.offset + this.offsetLeft}px`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['offsetBottom']) {
      this.updatePosition();
    } else if (changes['offsetTop']) {
      this.updatePosition();
    } else if (changes['offsetLeft']) {
      this.updatePosition();
    } else if (changes['offsetRight']) {
      this.updatePosition();
    }
  }

  // onClick() {
  //   this.click.emit();
  // }

}
