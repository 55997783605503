<div [style.max-height]="modalHeight" class="d-flex flex-column">
  <div class="dialog-head info-window-header">
    <div class="d-flex flex-column" style="max-width: calc(100% - 2rem);">
      <h4 class="modal-title w-100" style="height: 2.25rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" id="myModalLabel">{{title}}</h4>
      <h6 class="modal-title w-100" style="height: 1.5rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{sub1.value}}</h6>
      <h6 class="modal-title w-100" style="height: 1.5rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{sub2.value}}</h6>
    </div>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>

  </div>

  <div class="flex-fill dialog-body info-window-body" style="overflow-y: auto;"
  >

    <div *ngFor="let item of items" class="d-flex flex-column">

      <small class="item-label">{{item.title}}</small>
      <div class="ml-2 d-flex align-items-center justify-content-between" style="min-height: 27px;">

        <div *ngIf="item.valueType !='image'" class="" style="word-break: break-all;">{{item.value}}</div>
        <div *ngIf="item.valueType =='image'" class="my-4" style="display: flex; flex-direction: column; align-items: center; width: 100%;"><img style="max-width: 80%; max-height: 40vh; width: auto; height: auto; object-fit: contain;" [src]="item.value"></div>

        <a *ngIf="item.valueType =='phone' && phoneRegExp.test(item.value)" class="btn-floating btn-sm info-window-action-button" (click)="gotoPhoneApp(phoneNumberReplace(item.value))"
        >
          <i class="fas fa-phone"></i>
        </a>

        <a *ngIf="item.valueType =='url' && urlRegExp.test(item.value)" class="btn-floating btn-sm info-window-action-button" (click)="gotoOtherPage(item.value)"
        >
          <i class="fas fa-external-link-alt"></i>
        </a>

      </div>
      <hr class="mt-0 w-100 info-window-border" />
    </div>
  </div>

  <div class="dialog-foot info-window-footer">
    <div class="col-12 text-center">
      <button type="button" mat-raised-button class="col-6 mr-3 smb-primary-button" (click)="gotoMapApp()" style="padding: 0 5px;white-space: pre-wrap">地図アプリ起動</button>
      <button type="button" mat-raised-button class="col-4 smb-secondary-button" (click)="onCloseClick()">閉じる</button>
    </div>
  </div>

</div>
