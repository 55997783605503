import moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

export class StringUtils {
	static isEmpty(src) {
		return !src;
  }

  static isNotEmpty(src: string) {
		return !StringUtils.isEmpty(src);
	}

  static randomString(length: number): string {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static base64Encode(str: string): string {
    return btoa(unescape(encodeURIComponent(str)));
  }

  static base64Decode(str: string): string {
    return decodeURIComponent(escape(atob(str)));
  }
}
