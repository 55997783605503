import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// see https://traveler0401.com/angular-error-accessor
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MddbSelectComponent),
  multi: true
};

@Component({
  selector: 'app-mddb-select',
  templateUrl: './mddb-select.component.html',
  styleUrls: ['./mddb-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MddbSelectComponent implements OnInit, ControlValueAccessor {
  @Input() outline = false;
  @Input() options: { label: string, value: string }[] = [];
  @Input() ngModel: string;
  @Output() readonly ngModelChange = new EventEmitter<string>();
  @Input() placeholder = '';
  @Input() isRequired = false;
  @Input() label = '';

  constructor() { }
  writeValue(obj: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
  }

  onValueChange(event) {
    this.ngModelChange.emit(event);
  }
}
