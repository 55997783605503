import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { icon, latLng, Map, marker, point, polyline, tileLayer, circle, LatLngBounds, LayerGroup, LatLng, LatLngTuple, PanOptions, PointExpression, divIcon, TileLayer, LatLngExpression } from 'leaflet';
// import { SheetRepositoryService } from '../../repositories/sheet-repository.service';
// import { AppSettingUsecaseService } from '../../usecases/app-setting/app-setting-usecase.service';
import { Subscription, Subject, zip, combineLatest } from 'rxjs';
//import { isNumber } from 'util';
import { SheetDef, InitialPositionDef, InitialPositionLatLngDef, MapSourceType } from 'src/app/usecase/sheet-usecase.service';
import { filter } from 'rxjs/operators';
import { SvgMarker } from './marker';
import { app } from 'firebase';
import * as L from 'leaflet';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {

  private readonly sheetDefSub = new Subject<SheetDef>();
  private readonly initialPositionSub = new Subject<InitialPositionDef>();
  private readonly mapReadySub = new Subject<Map>();
  private readonly rowsSub = new Subject<any[]>();
  _initialPosition: InitialPositionDef;

  @Input() initialPos: InitialPositionLatLngDef | LatLngBounds;
  @Input() mapSourceType: MapSourceType;
  @Input() defaultMarkerColor: string = '#000000';

  @Input('sheetDef')
  set sheetDefSetter(value: SheetDef) {
    this.sheetDefSub.next(value);
  }

  @Input('initialPosition')
  set initialPositionSetter(value: InitialPositionDef) {
    this.initialPositionSub.next(value);
    //this._initialPosition = value;
  }

  @Input('rows')
  set rowsSetter(value: any[]) {
    this.rowsSub.next(value);
  }

  @Input() preventFromSelectedSheetChange: {count: number};

  @Input() fitBounds = true;
  @Output() readonly loaded = new EventEmitter<void>();
  @Output() readonly selectedMarkerChange = new EventEmitter<any>();
  @Output() readonly selectedMarkerDistance = new EventEmitter<any>();
  @Output() readonly moved = new EventEmitter<{ center: LatLngTuple, zoom: number }>();
  @Output() readonly clicked = new EventEmitter<void>();

  @Input() moveToCommand: (LatLngTuple)=>void;
  @Output() readonly moveToCommandChange = new EventEmitter<(LatLngTuple)=>void>();

  @Input() fitBoundsCommand: (Bounds)=>void;
  @Output() readonly fitBoundsCommandChange = new EventEmitter<(Bounds)=>void>();

  @Input() changeMapSourceTypeCommand: (MapSourceType)=>void;
  @Output() readonly changeMapSourceTypeCommandChange = new EventEmitter<(MapSourceType)=>void>();
  @Output() readonly mapReady = new EventEmitter();
  @Output() readonly componentReady = new EventEmitter();

  get tileLayer(): TileLayer {
    switch (this.mapSourceType) {
      case 'osm':
        return tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' });
      case 'chiriin':
        return tileLayer('https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png', {
          attribution: `<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>`
        });
      case 'pale':
        return tileLayer('https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png', {
          attribution: `<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院地図タイル一覧</a>`
        });
      case 'blank':
        return tileLayer('https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png', {
          attribution: `<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院地図タイル一覧</a>`
        });
      case 'seamless_photo':
        return tileLayer('https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg', {
          attribution: `<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院地図タイル一覧</a>`
        });
      case 'english':
        return tileLayer('https://cyberjapandata.gsi.go.jp/xyz/english/{z}/{x}/{y}.png', {
          attribution: `<a href='https://maps.gsi.go.jp/development/ichiran.html#english' target='_blank'>地理院地図タイル一覧</a>`
        });
      case 'all_rivers':
        return tileLayer('https://www.gridscapes.net/AllRivers/1.0.0/t/{z}/{x}/{y}.png', {
          tms: true,
          minZoom: 5,
          maxZoom: 13,
          attribution: `<a href='https://www.gridscapes.net/#AllRiversAllLakesTopography' target='_blank'>川だけ</a>`
        });
      case 'transport':
        return tileLayer('https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=ebf3e982386c48e5acf9bf77174dc687', {
          maxZoom: 18,
          attribution: `<a href="https://www.thunderforest.com/maps/transport/" target='_blank'>交通</a>`
        });
      case 'cycle':
        return tileLayer('https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=ebf3e982386c48e5acf9bf77174dc687', {
          maxZoom: 18,
          attribution: `<a href="https://www.thunderforest.com/maps/opencyclemap/" target='_blank'>Cycle</a>`
        });
      case 'water_color':
        return tileLayer('https://stamen-tiles-d.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: `<a href="http://maps.stamen.com/" target='_blank'>水彩</a>`
        });
    }
  }

  //地図自体の描写に必要なoptions
  options = {
    // layers: [
    //   // tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    //   this.tileLayer
    // ],
    // zoom: 6,
    // center: latLng(35.170915, 136.881537),
  };

  getOptions() {
    return this.options;
  }

  //マーカーと円を名古屋中心にあらかじめ配置する値
  layers = [
    // circle([ 35.170915, 136.881537 ], { radius: 500 })
  ];

  //図形描写ツールに関する値
  drawOptions = {
    position: 'none'
  };

  initialized = true;

  radiusObj = {
    near: 20,
    normal: 40,
    far: 80
  };

  selectedMarker: LatLngExpression;

  displayCluster = (countRows, cluster) => {
    return (cluster.mode === 'auto' && countRows >= 1000)
      || cluster.mode === 'enable';
  }

  private subscription: Subscription;

  private baseLayerGroup: LayerGroup;
  private markerLayerGroup: LayerGroup;
  private currentPositionGroup: LayerGroup;
  private gpsCircle;
  private gpsMarker;

  private markers = [];
  private map: Map;

  constructor(
  ) {
    this.subscription = combineLatest([
      this.sheetDefSub.pipe(filter(x => x != null)),
      this.initialPositionSub.pipe(filter(x => x != null)),
      this.rowsSub.pipe(filter(x => x != null)),
      this.mapReadySub]).subscribe(([sheetDef, initialPositionDef, rows, map]) => {
      console.debug(`${this.constructor.name}:constructor start`, sheetDef, rows.length);

      this.moveToCommandChange.emit((latLng) => {
        map.setView(latLng, map.getZoom());
      });
      this.fitBoundsCommandChange.emit((bounds) => {
        map.fitBounds(bounds);
      });

      this.changeMapSourceTypeCommandChange.emit((mapSourceType) => {
        this.mapSourceType = mapSourceType;
        if (this.baseLayerGroup != null) {
          this.baseLayerGroup.clearLayers();
        } else {
          this.baseLayerGroup = new LayerGroup().addTo(map);
        }
        this.baseLayerGroup.addLayer(this.tileLayer);
      });

      let minLat = Number.MAX_VALUE;
      let maxLat = Number.MIN_VALUE;
      let minLon = Number.MAX_VALUE;
      let maxLon = Number.MIN_VALUE;

      map.off("moveend");
      map.on("moveend", event => {
        const center: LatLngTuple = [map.getCenter().lat, map.getCenter().lng];
        const zoom = map.getZoom();
        console.debug(`${this.constructor.name}:moveend fired.`, event, center, zoom);
        map.invalidateSize();
        if (this.selectedMarker) {
          this.selectedMarkerDistance.emit(map.distance(center, this.selectedMarker));
        }
        this.moved.emit({ center: center, zoom: zoom });
      });
      map.off("click");
      map.on("click", event => {this.clicked.emit();});

      const isCluster = this.displayCluster(rows.length, sheetDef.cluster);

      if (this.markerLayerGroup != null) {
        for (const m of this.markers) {
          map.removeLayer(m);
        }
        this.markers = [];
        this.markerLayerGroup.clearLayers();
      }

      if (isCluster) {
        this.markerLayerGroup = L.markerClusterGroup({
          showCoverageOnHover: false,
          maxClusterRadius: this.radiusObj[sheetDef.cluster.distance]
        });
      } else {
        this.markerLayerGroup = new LayerGroup();
      }

      if (this.baseLayerGroup != null) {
        this.baseLayerGroup.clearLayers();
      } else {
        this.baseLayerGroup = new LayerGroup().addTo(map);
      }
      this.baseLayerGroup.addLayer(this.tileLayer);

      if (this.initialPos != null && this.fitBounds) {
        if (this.initialPos instanceof LatLngBounds) {
          map.fitBounds(this.initialPos);
        } else {
          map.setView(this.initialPos.center, initialPositionDef.zoom);
        }
      } else if (this._initialPosition
        && this.preventFromSelectedSheetChange.count == 0
        && (this._initialPosition.type=='latLonZoom'
          && initialPositionDef.type=='latLonZoom')
        && (this._initialPosition.latLonZoom.center[0] != initialPositionDef.latLonZoom.center[0]
          || this._initialPosition.latLonZoom.center[1] != initialPositionDef.latLonZoom.center[1]
          || this._initialPosition.zoom != initialPositionDef.zoom)
        && (typeof initialPositionDef.latLonZoom.center[0] == 'number'
          && typeof initialPositionDef.latLonZoom.center[1] == 'number')) {
        map.setView(initialPositionDef.latLonZoom.center, initialPositionDef.zoom);
      }

      this._initialPosition = {type: initialPositionDef.type} as InitialPositionDef;
      if (typeof initialPositionDef.gpsOption !== 'undefined') {
        this._initialPosition.gpsOption = {
          accuracy: initialPositionDef.gpsOption.accuracy
        }
      }
      if (typeof initialPositionDef.latLonZoom !== 'undefined') {
        this._initialPosition.latLonZoom = {
          center: [initialPositionDef.latLonZoom.center[0], initialPositionDef.latLonZoom.center[1]]
        } as InitialPositionLatLngDef;
      }

      let normalSvg = SvgMarker.normalSvg;
      normalSvg = normalSvg.replace(`fill="black"`, `fill='${this.defaultMarkerColor}'`);
      const markerImg = sheetDef.marker.defaultIconUrlColumn && sheetDef.marker.markerType !== "thematic";

      for (const row of rows) {
        try {
          const lat = Number(row[sheetDef.position.latColumn]);
          const lon = Number(row[sheetDef.position.lonColumn]);
          if (typeof lat!== 'number' || typeof lon!== 'number') {
            continue;
          }

          const falseColor = sheetDef.marker.emptyColor;
          let svg = SvgMarker.svg;
          for (let i = 0; i < 4; i++) {
            const markerItemDef = sheetDef.marker.items[i];
            const colId = markerItemDef.column;
            const trueValue = markerItemDef.trueValue;
            const trueColor = markerItemDef.color;
            const isTrue = row[colId] == trueValue;
            svg = svg.replace(`{color${i+1}}`, isTrue ? trueColor : falseColor);
          }

          let url =  'data:image/svg+xml;charset=utf8,' + encodeURIComponent(normalSvg);
          if (sheetDef.marker.markerType == "thematic" ) {
            url = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svg);
          } else if (sheetDef.marker.defaultIconUrlColumn) {
            url = row[sheetDef.marker.defaultIconUrlColumn];
          }

          const iconWidth = 40 * 1.2;
          const iconHeight = 40 * 1.2;
          const iconAnchor: PointExpression = [ iconWidth / 2, iconHeight ];
          const selectedWidth = iconWidth * 1.5;
          const selectedHeight = iconHeight * 1.5;
          const selectedAnchor: PointExpression = [ selectedWidth / 2, selectedHeight ];

          const m = marker([ lat, lon ], {
            icon: divIcon({
              iconSize: [ iconWidth, iconHeight ],
              iconAnchor: iconAnchor,
              popupAnchor:  [0, -iconHeight],
              className: 'wrap-icon',
              html: `<div class="block ${markerImg ? 'block-img' : ''}">
                        <img src="${url}" class="icon-img" alt="image">
                        ${markerImg ?
                        `<svg height="8" width="6">
                          <polyline points="0,0 3,8 6,0" style="fill:black;stroke:black;stroke-width:1"/>
                        </svg>` : ''}
                    </div>`
            })
          });

          minLat = Math.min(minLat, lat);
          maxLat = Math.max(maxLat, lat);
          minLon = Math.min(minLon, lon);
          maxLon = Math.max(maxLon, lon);

          const title = row[sheetDef.infoWindow.titleColumn];

          const content = sheetDef.infoWindow.items.reduce((prev, x) => {
            const index = sheetDef.infoWindow.items.findIndex(c => c.column === x.column);
            if (index < 0) {
              return prev;
            }

            const title = x.column; //schema.columns[index].column_title;
            const value = row[x.column];
            return prev + `${title}: ${value}<br/>`;
          }, '');

          m.addTo(this.markerLayerGroup)
          .on('popupopen', e => {
            // console.debug(`${this.constructor.name}:popupopen`, e, row);

            // マーカーが既に地図から削除済みの場合は何もしない
            if (m.getElement() != null) {
              const icon = m.options.icon;
              icon.options.iconSize = [selectedWidth, selectedHeight];
              icon.options.iconAnchor = selectedAnchor;
              m.setIcon(icon);
              m.bindPopup(e['popup']);
            }
            this.selectedMarker = m.getLatLng();
            this.selectedMarkerDistance.emit(map.distance(map.getCenter(), this.selectedMarker));
            this.selectedMarkerChange.emit(row);
          })
          .on('popupclose', e => {
            // console.debug(`${this.constructor.name}:popupclose`, e, row);

            // マーカーが既に地図から削除済みの場合は何もしない
            if (m.getElement() != null) {
              const icon = m.options.icon;
              icon.options.iconSize = [iconWidth, iconHeight];
              icon.options.iconAnchor = iconAnchor;
              m.setIcon(icon);
            }
          this.selectedMarkerChange.emit(null);
          this.selectedMarker = null;
          })
          .bindPopup(
            // `${title}<br/><br/>` +
            // content
            ''
          )
          ;

          this.markers.push(m);

        } catch (error) {
          console.info(`${this.constructor.name}:row process failed.`, row, error);
        }
      }
      this.markerLayerGroup.addTo(map);

      this.initialized = true;
      this.loaded.emit();
    });
   }

  ngOnInit() {
    // this.subscription = this.appSettingUsecase.appSchema.subscribe(async (schema :AppSchema) => {
    //   this.appSchema = schema;
    //   await this.initializeMap();
    // });
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    window.removeEventListener('focus',this.onFocus);
  }

  // iPadのSafariでPWAとしてホーム画面に追加すると、アプリを開きなおした時に地図が正しく描画されず周囲がグレーになる場合がある
  // 下記のスクロールを走らせることで地図の表示範囲をシステムに再認識させ、正しく描画させることができる
  onFocus = ()=> {
    window.scrollTo({top:-1});
  }

  async onMapReady(map: Map) {
    this.mapReadySub.next(map);
    this.map = map;
    this.mapReady.emit();
    window.addEventListener('focus',this.onFocus);
  }

  moveTo() {
    console.debug(`${this.constructor.name}:moveTo`);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onResized();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(event) {
    this.onResized();
  }

  onResized() {
    if (this.map) this.map.invalidateSize();
  }

  createGpsCircle(lat, lon, accuracy){
    if (!this.currentPositionGroup) {
      this.currentPositionGroup = new LayerGroup().addTo(this.map);
    }
    this.clearGpsCircle();
    this.gpsCircle = new L.Circle([lat, lon], accuracy, {
      fillColor: '#012999',
      color: '#012999',
      weight: 1,
      opacity: 1
    }).addTo(this.currentPositionGroup);

    this.gpsMarker = new L.CircleMarker([lat, lon], {
      stroke: false,
      radius: 5,
      fillColor: '#0000dd',
      fillOpacity: 1,
      className: 'blinking'
    }).addTo(this.currentPositionGroup);
  }

  clearGpsCircle() {
    if (this.gpsCircle != null) {
      this.gpsCircle.remove();
      this.gpsCircle = null;
    }
    if (this.gpsMarker != null) {
      this.gpsMarker.remove();
      this.gpsMarker = null;
    }
  }

  ngAfterViewInit() {
    this.componentReady.emit(this);
  }

}
