import { Component, OnInit } from '@angular/core';
import { SpreadsheetModel, SheetSearchParams } from 'src/app/usecase/sheet-usecase.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-sheet-search-modal',
  templateUrl: './sheet-search-modal.component.html',
  styleUrls: ['./sheet-search-modal.component.scss']
})
export class SheetSearchModalComponent {
  sheets: SpreadsheetModel[] = [];
  selectedSheet: SpreadsheetModel;
  searchParams: SheetSearchParams;
  toastr: ToastrService;

  get indicateOrderName(): string {
    return this.searchParams.order=='date' ? '' : '↓';
  }
  get indicateOrderTime(): string {
    return this.searchParams.order=='date' ? '↓' : '';
  }

  onSort: (boolean)=>void;
  onSearchSheet(): void {}
  createApp:(SpreadsheetModel)=> Promise<boolean>;
  clearRemainingError(): void {};

  ngOnInit(): void {}


  constructor(
    public activeModal: MatDialogRef<SheetSearchModalComponent>,
  ) {
  }

  onCloseClick() {
    this.activeModal.close();
  }

  onSheetClick(item: SpreadsheetModel) {
    this.selectedSheet = item;
  }

  onSearchSheetByEnter(event) {
    if (typeof event.keyCode !== 'undefined' && event.keyCode == 13) this.onSearchSheet();
  }

  async onCreateApp() {
    this.clearRemainingError();
    if(this.selectedSheet) {
      if (await this.createApp(this.selectedSheet)) this.activeModal.close();
    } else {
      this.toastr.error('スプレッドシートが選択されていません。', null, {closeButton: true})
    }
  }
}
