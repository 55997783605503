import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InitialPositionLatLngDef } from 'src/app/usecase/sheet-usecase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-initial-position-lat-lng-setting',
  templateUrl: './initial-position-lat-lng-setting.component.html',
  styleUrls: ['./initial-position-lat-lng-setting.component.scss']
})
export class InitialPositionLatLngSettingComponent implements OnInit {
  @Input() value: InitialPositionLatLngDef;
  @Input() zoomLevel: number;
  @Input() onModal: boolean;
  @Input() reservedSaveCount: {reservedSaveTime: number};
  @Output() readonly valueChange = new EventEmitter<InitialPositionLatLngDef>();
  @Output() readonly zoomLevelChange = new EventEmitter<number>();
  @Output() readonly applyMapPositionRequest = new EventEmitter<void>();

  get lat(): string {
    if (this.value == null || this.value.center == null) {
      return environment.defaultCoordinate.latitude + '';
    }
    return String(this.value.center[0]);
  }
  set lat(value: string) {
    this.reCreateValue({ lat: value });
  }
  latLocal: string;
  latLocalReadyToEdit: boolean = false;

  get lng(): string {
    if (this.value == null || this.value.center == null) {
      return environment.defaultCoordinate.longitude + '';
    }
    return String(this.value.center[1]);
  }
  set lng(value: string) {
    this.reCreateValue({ lng: value });
  }
  lngLocal: string;
  lngLocalReadyToEdit: boolean = false;

  latProblem: number = 0;
  lngProblem: number = 0;

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.lat !== this.latLocal) {
      this.latLocal = this.lat;
      this.checkLatLngProblem(this.latLocal,'lat');
    }
    if (this.lng !== this.lngLocal) {
      this.lngLocal = this.lng;
      this.checkLatLngProblem(this.lngLocal,'lng');
    }
  }

  onLatLngDecided(event) {
    if (event.type !== 'keyup' || event.keyCode == 13) {
      if (this.lat !== this.latLocal) {
        if (this.latProblem<2 && this.lngProblem<2) {
          this.reCreateValue({lat: this.latLocal});
          this.latLocalReadyToEdit = (event.type !== 'blur');
        } else if (event.type === 'blur') {
          this.latLocalReadyToEdit = true;
          if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime--;
        }
      } else if (this.lng !== this.lngLocal) {
        if (this.latProblem<2 && this.lngProblem<2) {
          this.reCreateValue({lng: this.lngLocal});
          this.lngLocalReadyToEdit = (event.type !== 'blur');
        } else if (event.type === 'blur') {
          this.lngLocalReadyToEdit = true;
          if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime--;
        }
      }
    }
  }

  onLatLngFocus(category: 'lat' |'lng') {
    if (category == 'lat') this.latLocalReadyToEdit = true;
    else this.lngLocalReadyToEdit = true;
  }

  onLatLngEdit(category: 'lat' |'lng') {
    var text: string;
    if (category == 'lat') {
      if (this.latLocalReadyToEdit && this.lat !== this.latLocal) {
        this.latLocalReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.latLocalReadyToEdit && this.lat === this.latLocal) {
        this.latLocalReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime--;
      }
      text = this.latLocal;
    } else {
      if (this.lngLocalReadyToEdit && this.lng !== this.lngLocal) {
        this.lngLocalReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.lngLocalReadyToEdit && this.lng === this.lngLocal) {
        this.lngLocalReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime--;
      }
      text = this.lngLocal;
    }
    this.checkLatLngProblem(text,category);
  }

  checkLatLngProblem(text: string, category: 'lat' |'lng') {
    if (text.search(/[^\d\.\-]/) > -1 || Number.isNaN(Number(text))) {
      if (category === 'lat') this.latProblem = 3;
      else this.lngProblem = 3;
    } else if (text === '') {
      if (category === 'lat') this.latProblem = 2;
      else this.lngProblem = 2;
    } else if ((category == 'lat'
      && (parseFloat(text) < environment.coordinateRange.sw.latitude || parseFloat(text) > environment.coordinateRange.ne.latitude))
    || (category == 'lng'
      && (parseFloat(text) < environment.coordinateRange.sw.longitude || parseFloat(text) > environment.coordinateRange.ne.longitude))) {
        if (category === 'lat') this.latProblem = 1;
        else this.lngProblem = 1;
    } else {
      if (category === 'lat') this.latProblem = 0;
      else this.lngProblem = 0;
    }
  }

  reCreateValue(update: { lat?: string, lng?: string }) {
    const cloned = Object.assign({}, this.value);

    if (update.lat != null) {
      if (cloned.center == null) {
        cloned.center = [0, 0];
      }
      cloned.center[0] = Number(update.lat);
    }

    if (update.lng != null) {
      if (cloned.center == null) {
        cloned.center = [0, 0];
      }
      cloned.center[1] = Number(update.lng);
    }

    this.value = cloned;
    this.valueChange.emit(this.value);
  }

  onZoomLevelChange(event: number) {
    this.zoomLevelChange.emit(event);
  }

  onApplyMapPosition() {
    this.applyMapPositionRequest.emit();
  }
}
