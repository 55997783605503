import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SheetSearchParams } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-sheet-search-order-select',
  templateUrl: './sheet-search-order-select.component.html',
  styleUrls: ['./sheet-search-order-select.component.scss']
})
export class ShhetSearchOrderSelectComponent implements OnInit {
  @Input() value: SheetSearchParams;
  @Output() readonly valueChange = new EventEmitter<SheetSearchParams>();

  readonly types: { value: string, label: string }[] = [
    { value: 'name', label: '名前順' },
    { value: 'date', label: '更新日時順' },
  ];

  constructor() { }

  ngOnInit() {
  }

  onValueChange(event: any) {
    this.valueChange.emit(event);
  }
}
