<div class="fab d-flex align-items-center justify-content-center"
  mat-ripple
  [style.right]="positionRight"
  [style.bottom]="positionBottom"
  [style.top]="positionTop"
  [style.left]="positionLeft"
>
  <i *ngIf="iconClass !== 'layers'" class="fas" [ngClass]="iconClass" style="font-size: 20px;"></i>
  <mat-icon *ngIf="iconClass === 'layers'">{{iconClass}}</mat-icon>
</div>

