export class SvgMarker {

  public static svg = `<?xml version="1.0" standalone="no"?>
  <svg width="300px" height="400px" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
          <path stroke="none" fill="gray" d="
            M  50 250
            L 250 250
            L 150 400
            L  50 250
            Z"/>

          <path stroke="none" fill="{color1}" d="
            M 0 150
            A 150 150 0 0 1 150 0
            L 150 100
            A 50 50 0 0 0 100 150
            L 0 150
            Z"/>

          <path stroke="none" fill="{color2}" d="
            M 150 0
            A 150 150 0 0 1 300 150
            L 200 150
            A 50 50 0 0 0 150 100
            L 150 0
            Z"/>

          <path stroke="none" fill="{color3}" d="
            M 300 150
            A 150 150 0 0 1 150 300
            L 150 200
            A 50 50 0 0 0 200 150
            L 300 150
            Z"/>

          <path stroke="none" fill="{color4}" d="
            M 150 300
            A 150 150 0 0 1 0 150
            L 100 150
            A 50 50 0 0 0 150 200
            L 150 300
            Z"/>
      </g>
  </svg>
  `;

  public static normalSvg = `<?xml version="1.0" encoding="iso-8859-1"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   width="425.963px" height="425.963px" viewBox="0 0 425.963 425.963" style="enable-background:new 0 0 425.963 425.963;"
   xml:space="preserve">
    <g>
      <path fill="black" d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
      c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
      c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
      C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
      c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"/>
    </g>
  </svg>`;
}
