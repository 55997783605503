import { Component, OnInit } from '@angular/core';
import { AuthUsecase } from './usecase/auth-usecase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SMAP for Spreadsheet';

  constructor (
    private auth: AuthUsecase,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // if (this.auth.user == null) {
    //   this.router.navigate(['/redirect']);
    // }
  }
}
