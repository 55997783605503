<div class="dialog-head">
  <h4 class="modal-title w-100 fs-20">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-body body-map-type">
  <app-mddb-select
    [label]="'地図の種類'"
    [options]="options"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)">
  </app-mddb-select>
</div>
<div class="dialog-foot">
  <div class="text-right">
    <button mat-raised-button type="button" class="mr-3 btn-primary" (click)="onYesClick()">決定</button>
    <button mat-raised-button type="button"  class="btn-outline-dark" aria-label="Close" (click)="onNoClick()">キャンセル</button>
  </div>
</div>
