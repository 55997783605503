import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PositionDef, SheetEntity, MarkerItemDef, InfoWindowDef, InfoWindowItemDef, SearchItemDef, SearchDef, SheetDef } from 'src/app/usecase/sheet-usecase.service';
import { RefreshFlags, AppUsecase } from 'src/app/usecase/app-usecase.service';
import * as randomColor from 'randomcolor';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sheet-setting-panel',
  templateUrl: './sheet-setting-panel.component.html',
  styleUrls: ['./sheet-setting-panel.component.scss']
})
export class SheetSettingPanelComponent implements OnInit {

  @Input()
  sheet: SheetEntity

  @Input()
  sheetDef: SheetDef

  @Input()
  reservedSaveCount: {reservedSaveTime: number};

  @Output()
  readonly onDirtyChange = new EventEmitter<boolean | RefreshFlags>();

  @Input()
  dataRows: Array<any>;

  @Input()
  listItemMap;

  get listDataRows() {
    return this.dataRows;
  }

  markerTypes: { value: string, label: string }[] = [
    { value: 'normal', label: '通常' },
    { value: 'thematic', label: '四方ピン' },
  ];

  clusterInfo: { value: string, label: string }[] = [
    { value: 'auto', label: '自動(1000件以上で有効)' },
    { value: 'enable', label: '常に有効' },
    { value: 'disable', label: '常に無効' },
  ];

  clusterDistance: { value: string, label: string }[] = [
    { value: 'near', label: '近い' },
    { value: 'normal', label: '普通' },
    { value: 'far', label: '遠い' },
  ];

  get columns(): string[] {
    const sheet = this.sheet;
    return sheet != null ? sheet.columns : [];
  }

  get positionDef(): PositionDef {
    try {
      const sheet = this.sheet;
      if (sheet.sheetDef != null && sheet.sheetDef.position != null) {
        return sheet.sheetDef.position;
      } else {
        return {} as PositionDef;
      }
    } catch (error) {
      console.debug(`${this.constructor.name}:positionDef set failed.`, error);
      return {} as PositionDef;
    }
  }

  get markerDefs(): MarkerItemDef[] {
    try {
      const sheet = this.sheet;
      if (sheet.sheetDef != null && sheet.sheetDef.marker != null && sheet.sheetDef.marker.items != null) {
        return sheet.sheetDef.marker.items;
      } else {
        return [];
      }
    } catch (error) {
      console.debug(`${this.constructor.name}:markerDefs set failed.`, error);
      return [];
    }
  }

  set markerDefs(value: MarkerItemDef[]) {
    const sheet = this.sheet;
    if (sheet != null) {
      sheet.sheetDef.marker.items = value;
    }
  }

  get infoWindowDef(): InfoWindowDef {
    try {
      const sheet = this.sheet;
      if (sheet.sheetDef != null && sheet.sheetDef.infoWindow != null) {
        return sheet.sheetDef.infoWindow;
      } else {
        return {} as InfoWindowDef;
      }
    } catch (error) {
      console.debug(`${this.constructor.name}:positionDef set failed.`, error);
      return {} as InfoWindowDef;
    }
  }

  get infoWindowItemDefs(): InfoWindowItemDef[] {
    return this.infoWindowDef.items;
  }
  set infoWindowItemDefs(value: InfoWindowItemDef[]) {
    this.infoWindowDef.items = value;
  }

  get searchDef(): SearchDef {
    try {
      const sheet = this.sheet;
      if (sheet.sheetDef != null && sheet.sheetDef.search != null) {
        return sheet.sheetDef.search;
      } else {
        return {} as SearchDef;
      }
    } catch (error) {
      console.debug(`${this.constructor.name}:searchDef set failed.`, error);
      return {} as SearchDef;
    }
  }

  get searchItemDefs(): SearchItemDef[] {
    return this.searchDef.items;
  }
  set searchItemDefs(value: SearchItemDef[]) {
    this.searchDef.items = value;
  }

  constructor(private appUsecase: AppUsecase) {}

  ngOnInit() {
  }

  onDirty(refreshMap: boolean | RefreshFlags = false) {
    this.onDirtyChange.emit(refreshMap);
  }

  onMarkerItemDefAdd() {
    this.markerDefs.push({
      column: this.sheet.columns[0],
      color: randomColor(),
      trueValue: '1',
    } as MarkerItemDef);
    this.onDirty(true);
  }

  onMarkerDefChange(index: number, item: MarkerItemDef) {
    this.markerDefs[index] = item;
    this.onDirty(true);
  }

  onMarkerDefDelete(index: number, item: MarkerItemDef) {
    this.markerDefs = this.markerDefs.filter((x, idx) => idx != index);
    this.onDirty(true);
  }

  onInfoWindowItemDefAdd() {
    const dataListRow =  this.dataRows.map(x => x[this.sheet.columns[0]]);
    let valueType =  this.appUsecase.createValueType(dataListRow);
    this.infoWindowItemDefs.push({
      column: this.sheet.columns[0],
      valueType: valueType,
    } as InfoWindowItemDef);
    this.onDirty(false);
  }

  onInfoWindowItemDefChange(index: number, item: InfoWindowItemDef) {
    this.onDirty(false);
  }

  onInfoWindowItemDefDelete(index: number, item: InfoWindowItemDef) {
    this.infoWindowItemDefs = this.infoWindowItemDefs.filter((x, idx) => idx != index);
    this.onDirty(false);
  }

  onSearchItemDefAdd() {
    this.searchItemDefs.push({
      column: this.sheet.columns[0],
      valueType: 'text',
    } as SearchItemDef);
    let flag = {refreshMap: false, searchItemChange: {type: 'add'}} as RefreshFlags;
    this.onDirty(flag);
  }

  onSearchItemDefChange(index: number, item: SearchItemDef) {
    let flag = {refreshMap: false, searchItemChange: {type: 'change', index: index}} as RefreshFlags;
    this.onDirty(flag);
  }

  onSearchItemDefDelete(index: number, item: SearchItemDef) {
    this.searchItemDefs = this.searchItemDefs.filter((x, idx) => idx != index);
    let flag = {refreshMap: false, searchItemChange: {type: 'delete', index: index}} as RefreshFlags;
    this.onDirty(flag);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.infoWindowItemDefs, event.previousIndex, event.currentIndex);
    this.onDirty(false);
  }

}
