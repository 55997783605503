import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import moment from 'moment';
declare var gapi: any;

export type SheetMetaData = { id: string, title: string, index: number, rows: any[][]};

@Injectable({ providedIn: 'root' })
export class GApiService {

  private pageToken: string = null;

  private isLoaded = false;

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }
  set accesToken(value: string) {
    localStorage.setItem('accessToken', value);
  }

  constructor(
  ) {
  }

  async signIn(): Promise<any> {
    await this.loadApi();
    const auth = gapi.auth2.getAuthInstance();
    const res = await auth.signIn();
    console.log(`${this.constructor.name}: GApiService -> signIn -> res`, res);

		const googleUser = gapi.auth2.getAuthInstance().currentUser.get();
    console.log(`${this.constructor.name}: GApiService -> signIn -> googleUser`, googleUser);

    const authResponse = googleUser.getAuthResponse();
    console.log(`${this.constructor.name}: GApiService -> signIn -> authResponse`, authResponse);

    return googleUser;
  }

  async signOut() {
    const auth = gapi.auth2.getAuthInstance();
    await auth.signOut();
    auth.disconnect();
  }

  async loadApi() {
    if (this.isLoaded) {
      return;
    }

    const loadAuth2 = () => {
      return new Promise((resolve, reject) => {
        gapi.load('client:auth2', () => {
          resolve();
        });
      });
    }

    await loadAuth2();

    await gapi.client.init({
      apiKey: environment.gapi.apiKey,
      clientId: environment.gapi.clientId,
      discoveryDocs: environment.gapi.discoveryDocs,
      scope: environment.gapi.scopes.join(' '),
    });

    this.isLoaded = true;
    console.debug(`${this.constructor.name}:loadApi finished.`);
  }

  async listDocuments(searchWord: string): Promise<any> {

    await gapi.client.load('drive', 'v3');
    if (!gapi.auth2.getAuthInstance().isSignedIn.get()) throw new Error('Not signed in to Google');

    let query = '';
    if (searchWord != null && searchWord.length > 0) {
      query = `fullText contains "${searchWord}" and `;
    }

    const res =
    await gapi.client.drive.files.list({
      'pageToken': this.pageToken,
      'q': `${query}mimeType="application/vnd.google-apps.spreadsheet"`,
      'orderBy': 'modifiedTime desc',
      'pageSize': 50,
      'fields': "nextPageToken, files(id, name, modifiedTime, owners)"
    });
    console.debug('list file finished.', res);
    return res.result;
  }

  async getCellData(docId: string): Promise<Map<string, SheetMetaData>> {
    await gapi.client.load('sheets', 'v4');
    if (!gapi.auth2.getAuthInstance().isSignedIn.get()) throw new Error('Not signed in to Google');

    const sheetData = await gapi.client.sheets.spreadsheets.get({
      spreadsheetId: docId,
      ranges: [],
      includeGridData: false
    });

    const firstSheetName = sheetData.result.sheets[0].properties.title;

    const ranges = [];

    const sheetMetaData = sheetData.result.sheets.filter(s => s.properties.sheetType === 'GRID').map(sheet => {
      ranges.push(`${sheet.properties.title}!A1:AM`);
      return {
        id: sheet.properties.sheetId,
        title: sheet.properties.title,
        index: sheet.properties.index,
      };
    });

    const res = new Map<string, { id: string, title: string, index: number, rows: any[][]}>();

    const batchData = await gapi.client.sheets.spreadsheets.values.batchGet({
      spreadsheetId: docId,
      ranges: ranges,
    });
    const valueRanges = batchData.result.valueRanges;

    sheetMetaData.map((data, index) => {
      res.set(data.id, {
        id: data.id,
        title: data.title,
        index: data.index,
        rows: valueRanges[index].values,
      });
    });

    return res;

    // const resCellData = await gapi.client.sheets.spreadsheets.values.get({
    //   //spreadsheetId: '1BxiMVs0XRA5nFMdKvBdBZjgmUUqptlbs74OgvE2upms',
    //   //range: 'Class Data!A2:E',
    //   spreadsheetId: docId, // '1Wx_010v-gH15p0hJvkXQfnh3PWrU5avf31Lv5DY7_bg',
    //   range: `${firstSheetName}!A1:AM`, // 'baby-ho_20190528_1!A2:E',
    // });
    // console.debug('getCellData file finished.', resCellData);
    // return resCellData.result.values;
  }

  async compareDocModifiedTime(docId: string, lastSyncTime: string): Promise<boolean> {

    await gapi.client.load('drive', 'v3');
    if (!gapi.auth2.getAuthInstance().isSignedIn.get()) throw new Error('Not signed in to Google');

    const res = await gapi.client.drive.revisions.list({
      fileId: docId,
      fields: 'revisions(modifiedTime)',
    });

    return moment(res.result.revisions[res.result.revisions.length-1].modifiedTime).isAfter(lastSyncTime);

  }

}
