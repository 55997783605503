<div matRipple class="bottom-info-panel flex-fill d-flex flex-row" (click)="onInfoWindowModalShow()">
  <div class="flex-fill d-flex flex-column" [style.max-width]="sheetDef.infoWindow.bottomPanelImage ? 'calc(100% - 65px)' : '100%'">
    <div style="font-size: 16px;height: 24px;max-width: 100%;display: flex; justify-content: space-between">
      <div class="title-info">
        {{bottomInfoTitle}}
      </div>
      <div class="distance-info">
        {{distance}}
      </div>
    </div>
    <div style="opacity: 0.7;font-size: 14px;height: 21px;overflow: hidden;max-width: 100%;white-space: nowrap;text-overflow: ellipsis;">
      {{bottomInfoSub1}}
    </div>
    <div style="opacity: 0.7;font-size: 14px;height: 21px;overflow: hidden;max-width: 100%;white-space: nowrap;text-overflow: ellipsis;">
      {{bottomInfoSub2}}
    </div>
  </div>
  <div *ngIf="sheetDef.infoWindow.bottomPanelImage" style="height: 60px; width: 60px; margin-left: 5px; display: flex; align-items: center; outline-style: solid; outline-width: 1px;">
    <img [src]="bottomImage" style="width: 100%; height: auto; object-fit: contain; max-height: 100%;" (error)="onFailLoadImg($event)"/>
  </div>
</div>
