import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InitialPotisionType, SheetEntity } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-sheet-select',
  templateUrl: './sheet-select.component.html',
  styleUrls: ['./sheet-select.component.scss']
})
export class SheetSelectComponent implements OnInit {

  @Input() value: string;
  @Output() readonly valueChange = new EventEmitter<string>();

  @Input() checked: boolean;
  @Output() readonly checkedChange = new EventEmitter<boolean>();

  @Input()
  set sheets(value: SheetEntity[]) {
    this.options = value.map(x => ({ value: x.id, label: x.sheetName }));
  }

  options = [];

  // get options(): any[] {
  //   return this.sheets.map(x => ({ value: x.id, label: x.name }));
  // }

  // @Input() sheets: SheetEntity[] { value: string, label: string }[] = [
  //   { value: 'id1', label: 'シート１' },
  //   { value: 'id2', label: 'シート２' },
  //   { value: 'id3', label: 'シート３' },
  // ];


  constructor() { }

  ngOnInit() {
  }

  onValueChange(event: any) {
    this.value = event;
    this.valueChange.emit(this.value);
  }

  onCheckedChange(event: any) {
    this.checked = event;
    this.checkedChange.emit(this.checked);
  }

}
