<div>
  <ng-container *ngFor="let item of value.items; index as i">
    <div class="row">
      <label class="field-label col-12">
        {{item.column}}
      </label>
      <div class="col-12 mb-2">
        <ng-container *ngIf="item.valueType == 'text'">
          <mat-form-field class="w-100" color="warn">
            <input type="text" matInput [ngModel]="getValue(i)" (ngModelChange)="setValue(i, $event)" >
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="item.valueType == 'date'">
          <div class="d-flex align-items-center">
            <mat-form-field class="wrap-picker" color="warn">
              <input matInput [matDatepicker]="picker1" [ngModel]="getArrayValue(i, 0)" (ngModelChange)="setArrayValue(i, 0, $event)">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <span class="mx-2">～</span>
            <mat-form-field class="wrap-picker" color="warn">
              <input matInput [matDatepicker]="picker2" [ngModel]="getArrayValue(i, 1)" (ngModelChange)="setArrayValue(i, 1, $event)">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="item.valueType == 'list'">
          <app-mddb-select-object
            [outline]="true"
            [options]="listItemMap.get(item.column)"
            [placeholder]="item.column + 'を選択'"
            [ngModel]="getValue(i)" (ngModelChange)="setValue(i, $event)">
          </app-mddb-select-object>

        </ng-container>

      </div>
    </div>
  </ng-container>
</div>
