<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"></ngx-loading>

<div *ngIf="!initialDataLoaded" class="d-flex align-items-center justify-content-center smb-splash"
  [style.background-color]="splashBackgroundColor" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
  >
  <div class="text-center">
    <img style="width: 50px; height: 50px;" [src]="appImage">
    <h2 class="card-title h1-responsive pt-2 mb-5 font-bold app-name"><strong>{{splashAppTitle}}</strong></h2>
    <div class="spinner-custom"></div>
  </div>
</div>


<div *ngIf="initialDataLoaded" class="d-flex flex-column" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">

  <!-- タイトルバー -->
  <mat-toolbar  *ngIf="!showSideBar"
                class="smb-app-bar mat-elevation-z2"
                style="display: flex; height: 64px; z-index: 1041">
    <mat-toolbar-row layout-align="start center" class="p-0 special-row">
      <button mat-icon-button class="mr-2" (click)="toggleSideNav()"><mat-icon>menu</mat-icon></button>
      <span style="text-overflow: ellipsis;overflow: hidden; white-space: nowrap; display: block;">{{app.name}}</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="w-100 {{ showSideBar ? 'notice-admin' : ''}}" *ngIf="showNotification">
    <div class="marquee" style="width: 100%;">
      <span style="white-space: nowrap; display: block;">{{app.notification}}</span>
    </div>
  </div>

  <div class="flex-fill d-flex">

    <div *ngIf="showSideBar" class="map-side-bar d-flex flex-column bg-primary text-light"
         style="min-width: 250px; max-width: 250px; flex-basis: 250px; z-index: 2; overflow-y: auto; overflow-x: hidden"
         [style.maxHeight.px]="viewHeight">
      <app-app-setting-panel
        [app]="app"
        [sheet]="sheet"
        [currentCamera]="currentCamera"
        [(selectedSheetId)]="selectedSheetId"
        [(selectedSheetEnabled)]="selectedSheetEnabled"
        [deleteAppPromise]="onDeleteApp"
        (backClick)="back()"
        (onDirtyChange)="onDirty($event)"
        (reloadClick)="onReload()"
        (requestMapPosition)="onRequestMapPosition($event)"
        (clearRemainingError)="clearRemainingError()"
        [reservedSaveCount]="reservedSaveCount"
        (initGps)="onChangeGps($event)"
        (moveToAllMarker)="onMoveToAllMarker()"
        (imageUploadStart)="imageUploadStart()"
        (imageUploadFinish)="imageUploadFinish()"
        (onDefaultShowChange)="onChangeDefaultShow($event)"
        (onMapTypeChange)="setDefaultMapTypeDialog($event)"
      ></app-app-setting-panel>

    </div>

    <div class="flex-fill" [style.max-width]="showSideBar ? 'calc(100% - 650px)' : '100%'">

      <div
        class=""
        [style.height.px]="map_height"
        [style.margin-top]="(showNotification && showSideBar)? '40px' : '0'"
      >
        <app-map-view
          #mapView

          [hidden]="mapOrListIconClass == 'fa-map'"
          [sheetDef]="sheetDef"
          [rows]="filteredRows"
          [initialPos]="initialPos"
          [defaultMarkerColor]="emptyMarkerColor"
          [mapSourceType]="sheet.mapSourceType"
          [initialPosition]="sheet.initialPosition"
          [fitBounds]="fitBounds"
          [preventFromSelectedSheetChange]="preventFromSelectedSheetChange"
          (loaded)="onMapLoaded()"
          (moved)="onMapMoved($event)"
          (clicked)="onMapAreaClicked()"
          (selectedMarkerChange)="onSelectedMarkerChange($event)"
          (selectedMarkerDistance)="onMarkerChangeDistance($event)"
          [(changeMapSourceTypeCommand)]="changeMapSourceType"
          [(moveToCommand)]="moveTo"
          [(fitBoundsCommand)]="fitBoundsCommand"
          (mapReady)="onMapReady()"
          (componentReady)="onMapViewComponentReady($event)"
        ></app-map-view>

        <ng-container
          *ngIf="mapOrListIconClass == 'fa-map'">

          <div style="position:relative; width: 100%; height: 100%; overflow-y: auto;" (click)="onMapAreaClicked()">
            <div style="width: 100%;">
              <div style="margin-bottom: 1rem;"></div>
              <div *ngFor="let row of orderedFilteredRows; let i = index;">
                <app-row-summary-panel
                  [theme]="theme"
                  [sheetDef]="sheetDef"
                  [selectedRow]="row"
                  [isMobile]="isMobile"
                  [isAndroid]="isAndroid"
                  [distance]="arrDistance[i]"
                  (clearRemainingError)="clearRemainingError()"
                ></app-row-summary-panel>
                <hr class="hr-dark">
              </div>
            </div>
          </div>

        </ng-container>

        <div style="position: absolute; left: 0; right: 0;">
          <app-bottom-panel
            [theme]="theme"
            [sheetDef]="sheetDef"
            [selectedRow]="selectedRow"
            [contentHeight]="bottomInfoPanelHeight"
            [showSideBar]="showSideBar"
            [isMobile]="isMobile"
            [isAndroid]="isAndroid"
            [bottomDistance]="selectedMarkerDistance"
          ></app-bottom-panel>
        </div>

      </div>

      <div id="bottom-search-panel" class="bottom-info-panel d-flex flex-column px-2"
        [style.left.px]="leftBarWidth"
        [style.right.px]="rightBarWidth"
        [style.bottom.px]="'60'"
        [style.height.px]="bottomSearchPanelHeight"
      >
        <div class="p-2">
          <app-search-panel
            [listItemMap]="listItemMap"
            [value]="sheet.sheetDef.search"
            [resultMap]="resultMap"
          ></app-search-panel>
        </div>
        <div class="px-2 d-flex justify-content-center pb-2">
          <button mat-raised-button class="btn-search mr-2" (click)="onSearchClear()">
            クリア
          </button>
          <button mat-raised-button class="btn-search" (click)="onSearchApply()">
            決定
          </button>
        </div>
      </div>

      <div style="height: 60px; z-index: 999;"

        class="d-flex justify-content-between smb-app-bottom-panel"
      >
        <div class="d-flex justify-content-center align-items-center flex-column"
          style="cursor: pointer; width: 100%;"
          *ngFor="let sheet of enabledSheets; let i = index;"
          [ngClass]="sheet.id == selectedSheetId ? 'smb-bottom-tab-selected' : ''"
          (click)="selectedSheetId = sheet.id"
          >
          <i class="material-icons">{{sheet.icon}}</i>
          <small class="sheet-name">{{sheet.name}}</small>
        </div>

      </div>

    </div>

    <div *ngIf="showSideBar" style="min-width: 400px; max-width: 400px; flex-basis: 400px; overflow-y: auto; overflow-x: hidden" [style.maxHeight.px]="viewHeight">

      <app-sheet-setting-panel *ngIf="selectedSheetEnabled"
        [listItemMap] = "listItemMap"
        [sheet]="sheet"
        [sheetDef]="sheetDef"
        (onDirtyChange)="onDirty($event)"
        [reservedSaveCount]="reservedSaveCount"
        [dataRows]="dataRowsApp"
      ></app-sheet-setting-panel>

    </div>
  </div>

</div>

<ng-container *ngIf="initialDataLoaded">

  <app-fav
    *ngIf="sheetDef.search.items.length > 0"
    iconClass="fa-search"
    position="bottomLeft"
    [offsetLeft]="favOffsetLeft"
    [offsetBottom]="favOffsetBottom"
    (click)="onClickSearch()"
  ></app-fav>

  <app-fav
    [iconClass]="mapOrListIconClass"
    position="topRight"
    [offsetRight]="favOffsetRight"
    [offsetTop]="favOffsetTop"
    (click)="onClickList()"
  ></app-fav>

  <app-fav
    *ngIf="mapOrListIconClass == 'fa-list'"
    [iconClass]="'layers'"
    position="topRight"
    [offsetRight]="favOffsetRight"
    [offsetTop]="favLayerOffsetTop"
    (click)="onClickMapType()"
  ></app-fav>

  <app-fav
    *ngIf="mapOrListIconClass == 'fa-list'"
    class="bottom-fav"
    iconClass="fa-location-arrow"
    position="bottomRight"
    [ngClass]="toggleGPS ? 'animate-gps' : ''"
    [offsetRight]="favOffsetRight"
    [offsetBottom]="favOffsetBottom"
    (click)="onChangeGps()"
  ></app-fav>

  <app-fav
    *ngIf="mapOrListIconClass != 'fa-list'"
    class="bottom-fav"
    [iconClass]="searchOrderIsAsc ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-down'"
    position="bottomRight"
    [offsetRight]="favOffsetRight"
    [offsetBottom]="favOffsetBottom"
    (click)="searchOrderIsAsc = !searchOrderIsAsc"
  ></app-fav>

  <!-- Sidebar [hidden]="!showSideNav"-->
  <div  class="fixed side-nav"
    [style.width.px]="sideNavWidth"
    >
    <div class="smb-app-sidebar">
<!--      <h4 class="">-->
<!--        <i class="material-icons" style="font-size: 2.5rem;">{{app.icon}}</i>-->
<!--      </h4>-->
      <img class="icon-side" [src]="appImage">
      <div *ngIf="app.author" class="mt-3">作成者：</div>
      <div *ngIf="!app.author" class="mt-3">作成者：-</div>
      <div class="ml-1 text-break">{{app.author}}</div>
      <div *ngIf="app.description" class="mt-2">アプリの説明：</div>
      <div class="ml-1 text-break">{{app.description}}</div>
      <div class="d-flex">
        <div *ngIf="app.question !== ''" class="mt-3 mr-3 icon-contact">
          <a target="_blank" href="{{app.question}}">
            <mat-icon>help</mat-icon>
          </a>
        </div>
        <div *ngIf="app.contact !== ''" class="mt-3 icon-contact">
          <a target="_blank" href="{{app.contact}}">
            <mat-icon>assignment</mat-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="bottom-info-side">
      <h6>アプリの共有QRコード</h6>
      <ngx-qrcode
        [elementType]="'url'"
        [value] = "userUrl"
        class="qrcode-side"
        errorCorrectionLevel = "L">
      </ngx-qrcode>
      <img src="/assets/logo_side.ico">
      <div>Made with</div>
      <a target="_blank" href="https://">どすごいマップ</a>
      <div>Ver1.1</div>
    </div>
  </div>


  <!-- TODO Sidebar navigation
  <mdb-side-nav [hidden]="showSideBar" #sidenav class="fixed" [fixed]="true">

    <div class="p-4 smb-app-sidebar">
      <h4>
        <i class="material-icons" style="font-size: 2.5rem;">{{app.icon}}</i>
      </h4>
      <h4>
        {{app.name}}
      </h4>
      <div class="mt-2">作者:</div>
      <div class="ml-1">{{app.author}}</div>
      <div class="mt-2">アプリの説明:</div>
      <div class="ml-1">{{app.description}}</div>
    </div>


  </mdb-side-nav> -->
  <!--/. Sidebar navigation -->

</ng-container>


