import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './ui/page/start/start.component';
import { RedirectComponent } from './ui/page/redirect/redirect.component';
import { AppListComponent } from './ui/page/app-list/app-list.component';
import { MapPageComponent } from './ui/page/map-page/map-page.component';
import { NoAppComponent } from './ui/page/no-app/no-app.component';
import { LoginGuard } from './service/login.guard';

const routes: Routes = [
  { path: 'redirect', component: RedirectComponent },
  { path: 'start', component: StartComponent },
  { path: 'app_list', component: AppListComponent, canActivate: [LoginGuard] },
  { path: 'map_page', component: MapPageComponent, canActivate: [LoginGuard] },
  { path: '', component: MapPageComponent, canActivate: [LoginGuard] },
  { path: 'no_app', component: NoAppComponent},
  { path: '**', redirectTo: '/redirect' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    // enableTracing: true
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
