import { Component, OnInit, Input } from '@angular/core';
import { SearchDef, Filter } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit {
  @Input()
  value: SearchDef;

  @Input()
  listItemMap = new Map<string, { value: string; label: string; }[]>();


  result: { result: boolean, value?: Filter[] } = { result: false };

  @Input()
  resultMap = new Array<{column:string, value:string | string[]}>();

  getValue(index: number) {
    if (typeof this.resultMap[index] === 'undefined' || typeof this.resultMap[index].value !== 'string') return '';
    return this.resultMap[index].value;
  }
  setValue(index: number, value: any) {
    if (typeof this.resultMap[index] !== 'undefined'
      && (typeof this.resultMap[index].value === 'string' || this.resultMap[index].value === null)) this.resultMap[index].value = value;
  }

  getArrayValue(index: number, startOrEnd: number) {
    if (typeof this.resultMap[index] === 'undefined' || typeof this.resultMap[index].value === 'string') return '';
    return this.resultMap[index].value[startOrEnd];
  }
  setArrayValue(index: number, startOrEnd: number, value: any) {
    if (typeof this.resultMap[index] !== 'undefined' && typeof this.resultMap[index].value !== 'string') {
      const arr = [];
      if (startOrEnd === 0) {
        arr[0] = value;
        arr[1] = this.resultMap[index].value[1];
      } else {
        arr[0] = this.resultMap[index].value[0];
        arr[1] = value;
      }
      this.resultMap[index].value = arr;
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
