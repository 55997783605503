import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InitialPotisionType } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-initial-position-type-select',
  templateUrl: './initial-position-type-select.component.html',
  styleUrls: ['./initial-position-type-select.component.scss']
})
export class InitialPositionTypeSelectComponent implements OnInit {
  @Input() value: InitialPotisionType;
  @Output() readonly valueChange = new EventEmitter<InitialPotisionType>();

  readonly types: { value: string, label: string }[] = [
    { value: 'boundingBox', label: 'マーカー全体' },
    { value: 'latLonZoom', label: '任意の位置' },
    { value: 'gps', label: 'GPSから取得' },
  ];

  constructor() { }

  ngOnInit() {
  }

  onValueChange(event: any) {
    this.valueChange.emit(event);
  }
}
