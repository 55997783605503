import { Injectable } from '@angular/core';
import { ShareModalComponent } from './share-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class ShareModalService {

  constructor(private modalService: MatDialog) {
  }

  async show(shareId: string): Promise<void> {
    console.debug(`${this.constructor.name}:show`, shareId);

    const modalRef = this.modalService.open(ShareModalComponent);
    const conpoment = modalRef.componentInstance as ShareModalComponent;
    conpoment.shareId = shareId;

    // await modalRef.result;
  }
}
