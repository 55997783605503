import { Injectable } from '@angular/core';
// import { MDBModalService } from 'ng-uikit-pro-standard';
import { first } from 'rxjs/operators';
import { InfoWindowModalComponent } from './info-window-modal.component';
import { AppThemeDef } from 'src/app/usecase/sheet-usecase.service';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class InfoWindowModalService {

  modalRef: MatDialogRef<InfoWindowModalComponent>;

  constructor(private modalService: MatDialog) {
  }

  show(
    theme: AppThemeDef,
    title: string,
    sub1: { title: string, value: string },
    sub2: { title: string, value: string },
    items: { title: string, value: string, valueType?: string }[],
    gotoMapApp: ()=>void,
    gotoPhoneApp: (string)=>void,
    gotoOtherPage: (string)=>void): Promise<boolean> {

    console.debug(`${this.constructor.name}:show`);

    const modalRef = this.modalService.open(InfoWindowModalComponent, {
      height: 'auto',
      maxHeight: '100vh',
      width: '600px',
      autoFocus: false
    });
    const component = modalRef.componentInstance as InfoWindowModalComponent;
    component.theme = theme;
    component.title = title;
    component.sub1 = sub1;
    component.sub2 = sub2;
    component.items = items;
    component.gotoMapApp = gotoMapApp;
    component.gotoPhoneApp = gotoPhoneApp;
    component.gotoOtherPage = gotoOtherPage;

    this.modalRef = modalRef;

    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(null);
      } catch (e) {
        reject(e);
      }
    });
  }

  close() {
    this.modalRef?.close();
  }

}
