import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppUsecase } from 'src/app/usecase/app-usecase.service';
import { environment } from 'src/environments/environment';

export type FileData = {
  name: string, // ドロップされたファイル名
  type: string, // imageBase64 にも入っているが扱いやすいようにプロパティにしておく
  imageBase64: string, // ファイルデータ(dataURI形式)
}

export type SupportType = {
  ext: string,
  type: string
};

export class BlobUtils {

  public static dataUriToFile(dataURI: string, fileName: string): File {

    // "iVBORw..."をバイナリに変換
    const byteString = atob(dataURI.split( "," )[1]);
    const mimeType = dataURI.match( /(:)([a-z\/]+)(;)/ )[2] ;

    // バイナリからBlobを作成
    for( var i=0, l=byteString.length, content=new Uint8Array( l ); l>i; i++ ) {
      content[i] = byteString.charCodeAt( i ) ;
    }

    return new File([content], fileName, {
      type: mimeType,
    });
  }

  public static getMimeType(dataURI: string): string {
    const base64ContentArray = dataURI.split(",");
    return base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
  }

  public static async fileToDataUri(file: File): Promise<{ name: string, data: string }> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          name: file.name,
          data: reader.result as string // dataURI形式
        });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}

@Component({
  selector: 'app-image-drop-panel',
  templateUrl: './image-drop-panel.component.html',
  styleUrls: ['./image-drop-panel.component.scss']
})
export class ImageDropPanelComponent implements OnInit {

  @Input() key = ''; // 未使用

  @Input() fileData: FileData = null;
  @Output() readonly fileDataChange = new EventEmitter<FileData>();

  @Input() hint = 'ここに画像をドロップ'
  @Input() showFrameAlways = true; // false の場合、画像をdropすると枠が消える

  @Input() isReadOnly = false;

  readonly id = Math.floor(Math.random() * 1000).toString();

  isDragoverOnFaceImageArea = false;

  private htmlEl: HTMLElement;
  dropFrame: HTMLElement;

  @Input() dataURI: string = null;
  @Output() readonly dataURIChange = new EventEmitter<string>();
  @Input() thumbnail: string = null;
  thumbnailSizeRatio = 0.9;

  @Input()
  supportTypes: SupportType[] = [
    { ext: 'png', type: 'image/png' },
    { ext: 'jpg', type: 'image/jpeg' },
    { ext: 'jpeg', type: 'image/jpeg' },
    { ext: 'gif', type: 'image/gif' },
    { ext: 'pdf', type: 'application/pdf' },
    { ext: 'ppt', type: 'application/vnd.ms-powerpoint' },
    { ext: 'pptx', type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
    { ext: 'doc', type: 'application/msword' },
    { ext: 'docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  ];

  private readonly icons = new Map<string, string>([
    ['pdf', 'assets/pdf_icon.png'],
    ['ppt', 'assets/ppt_icon.png'],
    ['pptx', 'assets/ppt_icon.png'],
    ['doc', 'assets/doc_icon.png'],
    ['docx', 'assets/doc_icon.png'],
  ]);

  constructor (
    el: ElementRef,
    private appUsecase: AppUsecase,
    private toastr: ToastrService,
  ) {
    this.htmlEl = el.nativeElement;
  }

  async ngOnInit() {
    this.dropFrame = this.htmlEl.getElementsByClassName('drop-frame')[0] as HTMLElement;
  }

  async ngAfterViewInit() {
    if (this.fileData != null) {
      const foundType = this.getSupportType(this.fileData.name, this.fileData.type);
      if (foundType != null) {
        this.dataURI = this.fileData.imageBase64;
        if (this.thumbnail == null) {
          this.thumbnail = this.isImageType(foundType) ? this.dataURI : this.icons.get(foundType.ext);
        }
        this.thumbnailSizeRatio = this.isImageType(foundType) ? 0.9 : 0.5;
      }
    } else if (this.dataURI != null) {
      if (this.thumbnail == null) {
        this.thumbnail = this.dataURI;
      }
      this.thumbnailSizeRatio = 0.9;
    }
  }

  private getSupportType(fileName: string, type: string ): SupportType {
    return this.supportTypes.find(x => {
      const lowerName = fileName.toLowerCase();
      const lowerType = type.toLowerCase();

      const lowerSupportExt = x.ext.toLowerCase();
      const lowerSupportType = x.type.toLowerCase();

      return lowerName.endsWith(`.${lowerSupportExt}`) || lowerType == lowerSupportType;
    });
  }

  private async loadImage(file: File) {

    const foundType = this.getSupportType(file.name, file.type);
    if (foundType == null) {
      this.toastr.error('サポートされていないファイル形式です。PNGまたはJPEG形式の画像をご使用ください。', null, {positionClass: 'toast-bottom-full-width'});
      return;
    }

    const fileData = await this.fileToFileData(file)
    this.dataURI = this.isImageType(foundType) ? fileData.imageBase64 : this.icons.get(foundType.ext);
    this.dataURIChange.emit(this.dataURI);

    this.thumbnail = this.isImageType(foundType) ? this.dataURI : this.icons.get(foundType.ext);
    this.thumbnailSizeRatio = this.isImageType(foundType) ? 0.9 : 0.5;

    this.fileData = fileData;
    this.fileDataChange.emit(this.fileData);
  }

  private async fileToFileData(file: File): Promise<FileData> {
    const ret = await BlobUtils.fileToDataUri(file);
    if (ret == null) {
      return null;
    }
    let canvas = document.createElement('canvas');
    if (typeof canvas.getContext == 'function') {
      let image = new Image();
      await this.appUsecase.loadImage(image, ret.data);
      let context = canvas.getContext('2d');
      canvas.width = environment.iconSize.width;
      canvas.height = environment.iconSize.width;
      context.fillStyle = '#fff';
      context.fillRect(0,0,canvas.width,canvas.height);
      context.fill();
      let dw = canvas.width;
      let dh = canvas.height;
      if (image.naturalWidth > image.naturalHeight) dh = image.naturalHeight * canvas.height / image.naturalWidth;
      else if (image.naturalWidth < image.naturalHeight) dw = image.naturalWidth * canvas.width / image.naturalHeight;
      let dx = (canvas.width - dw) / 2;
      let dy = (canvas.height - dh) / 2;
      context.drawImage(image, dx, dy, dw, dh);
      let imageString = canvas.toDataURL('image/png');

      return {
        name: 'appicon.png',//ret.name,
        type: BlobUtils.getMimeType(imageString),
        imageBase64: imageString,
      } as FileData;
    }

    return null;
  }

  private isFileDragged(event: any): boolean {
    // ファイルがドラッグされているときは、 effectAllowed == all,
    // 順序入れ替えのときは effectAllowed == move になっている。
    return (event?.dataTransfer?.effectAllowed ?? 'move') === 'all';
  }

  onDragOver(event: any) {
    if (!this.isReadOnly) {
      this.isDragoverOnFaceImageArea = this.isFileDragged(event);
    }

    event.preventDefault();
  }

  onDragLeave(event: any) {
    this.isDragoverOnFaceImageArea = false;
    event.preventDefault();
  }

  async onDrop(event: any) {
    if (this.isReadOnly) {
      return;
    }

    this.isDragoverOnFaceImageArea = false;
    event.preventDefault();
    event.stopPropagation();

    if (!this.isFileDragged(event)) {
      return;
    }
    const file = event.dataTransfer.files[0];
    await this.loadImage(file);
  }

  async onFileChoose(files) {
    const file = files[0];
    await this.loadImage(file);
  }

  onDeleteImage(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dataURI = null;
    this.dataURIChange.emit(this.dataURI);
    this.thumbnail = null;

    this.fileData = null;
    this.fileDataChange.emit(this.fileData);
  }

  showFlag: boolean = false;
  selectedImageIndex: number = 0;
  get imageObject(): Array<object> {
    return [
      {
        image: this.dataURI,
        thumbImage: this.dataURI,
        title: '',
        alt: '',
      }
    ];
  };

  isImageType(supportType: SupportType): boolean {
    return supportType.type.startsWith('image/');
  }

  showPreview(index) {
    // const t = this.getSupportType(this.fileData.name, this.fileData.type);
    // if (this.isImageType(t)) {
    //   this.selectedImageIndex = index;
    //   this.showFlag = true;
    // } else {
    //   const file = BlobUtils.dataUriToFile(this.fileData.imageBase64, this.fileData.name);
    //   this.download(file);
    // }
  }

  closeEventHandler() {
      this.showFlag = false;
      this.selectedImageIndex = -1;
  }

  private download(file: File) {
    let uri;
    if (window.navigator.msSaveBlob) {
      // for IE
      window.navigator.msSaveBlob(file, file.name);
      return;
    }
    if (window.URL && window.URL.createObjectURL) {
      // for Chrome, Firefox, ...
      uri = URL.createObjectURL(file);
      console.log(uri);
      // download用のタグを生成しクリック後、削除
      const link = document.createElement('a');
      link.setAttribute('href', uri);
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      // other
      const fileReader = new FileReader();
      fileReader.onload = function() {
        // console.log(this.result);
        uri = this.result;
        const link = document.createElement('a');
        link.setAttribute('href', uri);
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      };
      fileReader.readAsDataURL(file) ;
    }
  }
}
