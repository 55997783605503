import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmModalContent } from './confirm-modal-content';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(private modalService: MatDialog) { }

  show(title: string, message: string, okCaption?: string, cancelCaption?: string): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalContent);
    const component = modalRef.componentInstance as ConfirmModalContent;
    if (component != null) {
      component.title = title;
      component.message = message;
      component.okCaption = okCaption || '決定';
      component.cancelCaption = cancelCaption || 'キャンセル';
    }

    return new Promise<boolean>((resolve, reject) => {
      try {
        modalRef.afterClosed().subscribe(result => {
          if (result === 'ok') {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}
