<div class="d-flex flex-column pointer" style="height: 100%;" matRipple
  [style.margin-left.px]="showSideBar ? '250' : '0'"
  [style.margin-right.px]="showSideBar ? '400' : '0'"
  [style.width]="showSideBar ? 'calc(100% - 650px)' : '100%'"
>
  <!-- <div style="height:20px; background-color: red;"></div> -->
  <div class="bottom-info-panel flex-fill d-flex flex-row" (click)="onInfoWindowModalShow()" style="position: relative; align-items: center; max-width: 100%; background: #f8f9fa; color: #000000; padding: 0 16px">

    <div class="flex-fill d-flex flex-column" [style.max-width]="sheetDef.infoWindow.bottomPanelImage ? 'calc(100% - 65px)' : '100%'">
      <div style="font-size: 20px; max-width: 100%; height: 30px; display: flex; justify-content: space-between">
        <div class="title-info">
          {{bottomInfoTitle}}
        </div>
        <div class="distance-info">
          {{bottomDistance}}
        </div>
      </div>
      <div style="font-size: 14px; max-width: 100%; height: 21px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
        {{bottomInfoSub1}}
      </div>
      <div style="font-size: 14px; max-width: 100%; height: 21px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
        {{bottomInfoSub2}}
      </div>
    </div>
    <div *ngIf="sheetDef.infoWindow.bottomPanelImage" style="height: 60px; width: 60px; margin-left: 5px; display: flex; align-items: center; outline-style: solid; outline-width: 1px;">
      <img [src]="bottomImage" style="width: 100%; height: auto; object-fit: contain; max-height: 100%;" (error)="onFailLoadImg($event)"/>
    </div>

  </div>

</div>
