<!--<ng-container *ngIf="label != null && label != ''">-->
<!--  <label [for]="id" class="field-label">{{label}}</label>-->
<!--</ng-container>-->
<!--<input type="text" [id]="id" class="form-control" [class.content-error]="contentError"-->
<!--       [class.content-warning]="contentWarning" name="ngModel" [(ngModel)]="ngModel"-->
<!--       (ngModelChange)="onValueChange($event)" (blur)="onBlur($event)" (focus)="onFocus($event)">-->
<mat-form-field class="full-width"
                [class.content-error]="contentError"
                [class.content-warning]="contentWarning"
                color="accent">
  <input matInput type="text" [id]="id" [placeholder]="label" [name]="inputName" [(ngModel)]="ngModel"
         (ngModelChange)="onValueChange($event)" (blur)="onBlur($event)" (focus)="onFocus($event)">
</mat-form-field>
