<div class="d-flex flex-column align-items-center">
  <label class="field-label">アプリケーションのアイコン</label>

  <div class="mt-1" style="width:100px; height: 100px;">
    <app-image-drop-panel
      [supportTypes]="supportTypes"
      [thumbnail]="thumbnail"
      [fileData]="img"
      (fileDataChange)="onAppIconChange($event)"
    >
      <div class="d-flex flex-column align-items-center p-2">
        <small class="text-secondary"><i class="fa fa-upload"></i>クリックまたは画像をドロップ</small>
      </div>
    </app-image-drop-panel>
  </div>

</div>
