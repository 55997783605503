  <div class="dialog-head">
    <h4 class="modal-title w-100" id="myModalLabel">スプレッドシートからアプリを新規作成</h4>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>

  </div>
  <div class="dialog-body container-fluid">
    <div class="row">
      <div class="col-8">
        <div class="row">

          <div class="col-6">
            <form class="input-group input-group-sm">
              <mat-form-field class="w-100" color="warn">
                <input matInput type="text" [ngModelOptions] = "{standalone: true}" (keyup)="onSearchSheetByEnter($event)" placeholder="キーワードで検索" [(ngModel)]="searchParams.searchWord">
                <button mat-button matSuffix type="button" (click)="onSearchSheet()">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </form>
          </div>

          <div class="col-4">
            <app-sheet-search-order-select
            [(value)]="searchParams.order"
            (valueChange)="onSort(false)"
            ></app-sheet-search-order-select>
          </div>

        </div>

      </div>

      <div class="col-12 mt-2" style="height: 50vh; overflow-y: auto;">
        <table class="table" style="cursor: default;">
          <thead>
            <tr>
              <th scope="col">スプレッドシート名{{indicateOrderName}}</th>
              <th scope="col">オーナー</th>
              <th scope="col">更新日時{{indicateOrderTime}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sheet of sheets" [class.selected-sheet]="selectedSheet&&sheet.id==selectedSheet.id" (click)="onSheetClick(sheet)">
              <td>
                <img src="https://img.icons8.com/color/48/000000/google-sheets.png" style="height: 30px; width: auto;">
                {{sheet.name}}
              </td>
              <td>{{sheet.owner}}</td>
              <td>{{sheet.modifiedTimeFormated}}</td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
  <div class="dialog-foot">
    <button mat-raised-button class="col-2 btn-secondary fs-16 mr-2" (click)="onCreateApp()">作成</button>
    <button mat-raised-button class="col-2 btn-primary fs-16" (click)="onCloseClick()">閉じる</button>
  </div>
