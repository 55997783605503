<div class="d-flex align-items-center">
  <div class="" style="flex-basis: 30px;"><small>{{no}}.</small></div>

  <app-mddb-select class="mr-1"
    [options]="options"
    [ngModel]="value.column"
    (ngModelChange)="onColumnChange($event)"
    [placeholder]="placeholder"
  ></app-mddb-select>

  <app-color-field class="mr-1 mb-3" style="min-width: 40px; max-width: 40px; cursor: pointer;"
    [(ngModel)]="value.color"
    pickerPosition="left"
  ></app-color-field>

  <app-text-field style="min-width: 50px; max-width: 50px;"
    placeholder="真値"
    [(ngModel)]="trueValue"
    (blur)="onTrueValueDecided($event)"
    (keyup)="onTrueValueDecided($event)"
    (focus)="onTrueValueFocus()"
    (ngModelChange)="onTrueValueEdit()"
  ></app-text-field>

  <button hidden type="button" class="close" style="flex-basis: 30px;" aria-label="Close" (click)="delete.emit()">
    <mat-icon>close</mat-icon>
  </button>

</div>
