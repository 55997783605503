<div class="card d-flex flex-column" style="border-color: #43A047; width: 200px; height: 150px;">

  <div class="flex-grow-1 my-2 mx-3">
    <img src="https://img.icons8.com/color/48/000000/google-sheets.png"  style="height: 40px; width: auto;">
    <h6>{{ item.name.substring(0, 30) }}</h6>
  </div>

  <div>
    <hr class="mt-0 mb-0" />
    <div class="d-flex flex-column my-2 mx-3">
      <small class="text-secondary align-self-end">{{ item.modifiedTimeFormated }} に更新</small>
    </div>
  </div>

  <!-- <div class="d-flex flex-column justify-content-between">
export type Sheet = {
  id: string,
  name: string,
  modifiedTime: string
};
    <h5>赤ちゃんの駅べびほっ</h5>
    <small class="text-secondary text-right">2020/01/31</small>
  </div> -->

</div>
