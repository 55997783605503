import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SupportType, FileData, BlobUtils } from '../image-drop-panel/image-drop-panel.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';
import { AppModel } from 'src/app/usecase/app-usecase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Input()
  shareId = '';
  @Output() readonly valueChange = new EventEmitter<string>();
  @Output() readonly imageUploadStart = new EventEmitter<void>();
  @Output() readonly imageUploadFinish = new EventEmitter<void>();

  img: FileData = null;
  thumbnail = '';

  supportTypes: SupportType[] = [
    { ext: 'png', type: 'image/png' },
    { ext: 'jpg', type: 'image/jpeg' },
    { ext: 'jpeg', type: 'image/jpeg' },
    // { ext: 'gif', type: 'image/gif' },
  ];

  onAppIconChange(event: FileData) {
    this.img = event;

    if (this.img) {
      // const filePath = event.name;
      const ref = this.storage.ref(`${this.shareId}/appicon.png`);
      const file = BlobUtils.dataUriToFile(event.imageBase64, event.name);
      if (file.size/1000000 > environment.iconSize.dataMB) {
        this.toastr.error('画像のサイズが大き過ぎるためアップロードできません。');
        return;
      }
      this.imageUploadStart.emit();
      ref.put(file).then(()=>{this.imageUploadFinish.emit();});

      this.valueChange.emit(event.imageBase64);
    }
  }

  time = new Date().getTime();

  constructor(
    private storage: AngularFireStorage,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const shareId = this?.shareId ?? 'default';
    this.thumbnail = `https://storage.googleapis.com/${environment.firebase.storageBucket}/${shareId}/appicon.png?v=${this.time}`;
  }

}
