  <div class="dialog-head">
    <h4 class="modal-title w-100" id="myModalLabel">アプリ公開</h4>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>

  </div>
  <div class="dialog-body container-fluid">
    <div class="row align-items-center">

      <div class="col-12 text-center">
          <!--<div>
            <h3 class="mb-0" style="font-weight: bold;">一般ユーザー</h3>
          </div>-->
          <div>
            <ngx-qrcode #qrcode
              [elementType]="elementType"
              [value] = "userUrl"
              errorCorrectionLevel = "L">
            </ngx-qrcode>
          </div>
        <div class="mb-2">
          <span class="fake-link" (click)='downloadQRCode()'>ダウンロード</span>
        </div>
          <div class="d-flex justify-content-center">
            <p class="text-center" style="word-break: break-word; max-width: 465px; min-width: 320px">
              {{userUrl}}
            </p>
          </div>
          <button type="button" mat-raised-button class="col-8" style="background-color: #0f478a;" color="primary" (click)="onCopy(true)">
            <mat-icon class="mb-1">file_copy</mat-icon>&nbsp;クリップボードへコピ
          </button>
<!--          <div class="mt-2" style="font-size: 16px;">QRコードもしくはURLをユーザーへ共有してください</div>-->
      </div>

    </div>

  </div>
  <div class="dialog-foot">
<!--    <button mat-raised-button class="mat-elevation-z2" style="background-color: #0D8563; color:white" (click)="onCloseClick()">閉じる</button>-->
  </div>
