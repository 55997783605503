import { Component, OnInit, Input } from '@angular/core';
import { AppModel } from 'src/app/usecase/app-usecase.service';
import * as color from 'color';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent implements OnInit {
  @Input() item: AppModel;

  time = new Date().getTime();

  get foregroundColor(): string {
    return color(this.item.color).luminosity() < 0.5 ? 'white' : 'black';
  }

  get appImage(): string {
    const shareId = this?.item?.shareId ?? 'default';
    return `https://storage.googleapis.com/${environment.firebase.storageBucket}/${shareId}/appicon.png?v=${this.time}`;
  }

  constructor() { }

  ngOnInit() {
  }

}
