import { Injectable } from '@angular/core';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { GApiService } from '../service/gapi.service';

export type UserEntity = {
  uid: string,
  email: string,
  photoURL: string,
  displayName: string,
};

export type UserCredentialEntity = {
  idToken?: string,
  accessToken? : string,
};

@Injectable({ providedIn: 'root' })
export class AuthUsecase {

  get user(): UserEntity {
    return JSON.parse(localStorage.getItem('user')) as UserEntity;
  }
  set user(value: UserEntity) {
    if (value != null) {
      localStorage.setItem('user', JSON.stringify(value));
    } else {
      localStorage.setItem('user', null);
    }
  }

  // get userCredential(): UserCredentialEntity {
  //   return JSON.parse(localStorage.getItem('credential')) as UserCredentialEntity;
  // }
  // set userCredential(value: UserCredentialEntity) {
  //   if (value != null) {
  //     localStorage.setItem('credential', JSON.stringify(value));
  //     // this.gapiService.accesToken = value.accessToken;
  //   } else {
  //     localStorage.setItem('credential', null);
  //     // this.gapiService.accesToken = '';
  //   }
  // }

  constructor(
      private afAuth: AngularFireAuth,
      private afs: AngularFirestore,
      private gapi: GApiService,
  ) {
  }

  async signInByGoogleApi(): Promise<boolean> {
    // see https://medium.com/@vanderlin/google-api-firebase-auth-1b18416b4e74
    const guser = await this.gapi.signIn();

    const provider = new auth.GoogleAuthProvider();
    for (const scope of environment.gapi.scopes) {
      provider.addScope(scope);
    }

    var credential = auth.GoogleAuthProvider.credential(guser.getAuthResponse().id_token);

    const res = await this.afAuth.auth.signInWithCredential(credential);
    // const res = await this.afAuth.auth.signInWithEmailAndPassword(environment.public_user.email, environment.public_user.pw);
    console.debug('credential', res);
    // this.userCredential = {
    //   idToken: res.credential['idToken'],
    //   accessToken: res.credential['accessToken']
    // } as UserCredentialEntity;

    this.user = {
      uid: res.user.uid,
      displayName: res.user.displayName,
      photoURL: res.user.photoURL,
      email: res.user.email,
    } as UserEntity;

    const tenantId = environment.tanantId;
    const userRef = this.afs.doc(`tenant/${tenantId}/user/${this.user.uid}`);
    await userRef.set(this.user);

    return res != null;
  }

  async signInForShare(): Promise<boolean> {

    // const res = await this.afAuth.auth.signInWithEmailAndPassword(environment.public_user.email, environment.public_user.pw);
    const res = await this.afAuth.auth.signInAnonymously();
    console.debug('credential', res);
    // this.userCredential = {
    //   idToken: res.credential['idToken'],
    //   accessToken: res.credential['accessToken']
    // } as UserCredentialEntity;

    this.user = {
      uid: res.user.uid,
      displayName: res.user.displayName,
      photoURL: res.user.photoURL,
      email: res.user.email,
    } as UserEntity;

    // const tenantId = environment.tanantId;
    // const userRef = this.afs.doc(`tenant/${tenantId}/user/${this.user.uid}`);
    // await userRef.set(this.user);

    return res != null;
  }

  // async refreshCredential() {
    // const cre = this.userCredential;
    // const gcre = auth.GoogleAuthProvider.credential(cre.idToken, cre.accessToken);
    // const res = await this.afAuth.auth.signInWithCredential(gcre);

    // this.user = {
    //   uid: res.user.uid,
    //   displayName: res.user.displayName,
    //   photoURL: res.user.photoURL,
    //   email: res.user.email,
    // } as UserEntity;

    // this.userCredential = {
    //   idToken: res.credential['idToken'],
    //   accessToken: res.credential['accessToken']
    // } as UserCredentialEntity;
  // }

  async signOut() {
    await this.afAuth.auth.signOut();
    await this.gapi.signOut();
    // this.userCredential = null;
    this.user = null;
  }

  get isSignedIn(): boolean {
    return this.user != null;
  }

  get isAdminSignedIn(): boolean {
    return this.user != null && (this.user.email != null);
  }

}
