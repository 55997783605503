<div class="wrap-modal-setting">
  <div class="dialog-head">
    <h4 class="title w-100" id="myModalLabel">アプリ設定</h4>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>

  </div>
  <div class="dialog-body container-fluid">
    <div class="row">
      <div class="col-8">
        <div class="row">

          <div class="col-12">
            <app-text-field
              label="アプリケーション名"
              [inputName] = "'AppName'"
              [(ngModel)]="name"
              (ngModelChange)="onTextEdit('name')"
              (blur)="onTextDecided($event)"
              (keyup)="onTextDecided($event)"
              (focus)="onTextFocus('name')"
            ></app-text-field>
          </div>

          <!-- <div class="col-1">
            <app-icon-field
              label="アイコン"
              [(ngModel)]="app.icon"
              (ngModelChange)="onDirty(false)"
            ></app-icon-field>
          </div> -->

          <div class="col-10">
            <app-text-field
              label="作成者"
              [inputName] = "'Auther'"
              [(ngModel)]="author"
              (ngModelChange)="onTextEdit('author')"
              (blur)="onTextDecided($event)"
              (keyup)="onTextDecided($event)"
              (focus)="onTextFocus('author')"
            ></app-text-field>
          </div>

          <div class="col-2">
            <app-color-field
              label="色"
              [(ngModel)]="app.color"
              (ngModelChange)="onAppColorChanged()"
            ></app-color-field>
          </div>

        </div>

      </div>
      <div class="col-4">
        <app-image-upload
          [shareId]="app.shareId"
          (valueChange)="onImageChanged($event)"
          (imageUploadStart)="imageUploadStart()"
          (imageUploadFinish)="imageUploadFinish()"
        ></app-image-upload>
      </div>


      <div class="col-12">
        <app-text-field
          label="説明"
          [inputName] = "'Description'"
          [(ngModel)]="description"
          (ngModelChange)="onTextEdit('description')"
          (blur)="onTextDecided($event)"
          (keyup)="onTextDecided($event)"
          (focus)="onTextFocus('description')"
        ></app-text-field>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-4" style="padding-right: 0;">
            <app-text-field
              label="公開URL"
              [inputName] = "'SubDomain'"
              [class.content-error]="!shareIdPublishEnable"
              [(ngModel)]="shareId"
              (ngModelChange)="onShareIdChanged()"
              (blur)="onShareIdPublished($event)"
              (keyup)="onShareIdPublished($event)"
            ></app-text-field>
            <div class="field-label error-mess" *ngIf="!shareIdPublishEnable">
              {{shareIdErrorMessage}}
            </div>
          </div>
          <div class="col-4 p-0 m-0">
            <div class="field-label" style="margin-top: 1.5rem;">{{domain}}</div>
          </div>
          <div class="col-2">
            <button mat-raised-button class="btn-margin mb-2 mat-elevation-z2" style="width: 167px; background-color: #f7c626;color:#525252;margin-top: 13px" [class.btn-disabled]="!shareIdPublishEnable" (click)="shareIdPublishEnable&&onShareModalShow()">
              <mat-icon>share</mat-icon>
              公開
            </button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <app-text-field
          label="お知らせ"
          [inputName] = "'Notice'"
          [(ngModel)]="notification"
          (ngModelChange)="onTextEdit('notification')"
          (blur)="onTextDecided($event)"
          (keyup)="onTextDecided($event)"
          (focus)="onTextFocus('notification')"
        ></app-text-field>
      </div>

      <div class="col-12">
        <app-text-field
          label="よくある質問"
          [inputName] = "'FAQ-URL'"
          [class.content-error]="!questionValid"
          [(ngModel)]="question"
          (ngModelChange)="onTextEdit('question')"
          (blur)="onTextDecided($event)"
          (keyup)="onTextDecided($event)"
          (focus)="onTextFocus('question')"
        ></app-text-field>
        <div class="field-label error-mess" *ngIf="!questionValid">
          リンクを指定してください。指定するとアプリのメニューにアイコンが現れます。
        </div>
        <app-text-field
          label="問い合わせ先"
          [inputName] = "'ContactUs-URL'"
          [class.content-error]="!contactValid"
          [(ngModel)]="contact"
          (ngModelChange)="onTextEdit('contact')"
          (blur)="onTextDecided($event)"
          (keyup)="onTextDecided($event)"
          (focus)="onTextFocus('contact')"
        ></app-text-field>
        <div class="field-label error-mess" *ngIf="!contactValid">
          リンクを指定してください。指定するとアプリのメニューにアイコンが現れます。
        </div>
      </div>

      <div class="col-3">
        <mat-form-field color="warn" class="w-100">
          <mat-label>自動消滅期限</mat-label>
          <input matInput
                 [name] = "'AutoDeleteDate'"
                 [(ngModel)]="deleteDate"
                 (dateChange)="onDeleteDateChange()"
                 (keyup)="onDateKeyup($event)"
                 [min]="minDate"
                 [formControl]="dateFormat"
                 [matDatepicker]="pickerDelete">
          <mat-datepicker-toggle matSuffix [for]="pickerDelete"></mat-datepicker-toggle>
          <mat-datepicker #pickerDelete></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="streamValueDeleteDate" class="clear-block">
        <mat-icon (click)="onClearDeleteDate()">backspace</mat-icon>
      </div>

<!--      <div class="block-update">-->
<!--        <app-mddb-select-->
<!--          label="定期再読み込み"-->
<!--          [(ngModel)]="updateType"-->
<!--          (ngModelChange)="onTypeUpdateChange()"-->
<!--          [options]="updateTypeRef">-->
<!--        </app-mddb-select>-->
<!--      </div>-->

<!--      <div class="block-update-frequency">-->
<!--        <app-mddb-select-->
<!--          *ngIf="showFrequency"-->
<!--          [(ngModel)]="updateFrequency"-->
<!--          (ngModelChange)="onUpdateFrequencyChange()"-->
<!--          [options]="updateFrequencyRef[updateType]">-->
<!--        </app-mddb-select>-->
<!--      </div>-->

    </div>
  </div>
  <div class="dialog-foot">
    <div class="line-foot mat-elevation-z1"></div>
    <button mat-raised-button class="mat-elevation-z2" style="color:white;background-color: #ff5252;" color="accent" (click)="onDeleteAppOuter()">アプリケーション削除</button>
    <button mat-raised-button class="mat-elevation-z2 col-2 " style="background-color: #0D8563; color:white" (click)="onCloseClick()">閉じる</button>
  </div>
</div>
