<!-- <app-mddb-select
  [outline]="true"
  [options]="options"
  [ngModel]="value"
  (ngModelChange)="onValueChange($event)"
  [placeholder]="placeholder"
  [label]="title">
</app-mddb-select> -->
<div class="block-search">
  <div class="no-item"><small>{{no}}.</small></div>

  <div class="d-flex align-items-center">

    <app-mddb-select class="mr-2"
      [options]="options"
      [ngModel]="value.column"
      (ngModelChange)="onColumnChange($event)"
      [placeholder]="placeholder"
    ></app-mddb-select>

    <app-mddb-select class="mr-2"
      [options]="valueTypes"
      [ngModel]="value.valueType"
      (ngModelChange)="onValueTypeChange($event)"
      [placeholder]="'種類'"
    ></app-mddb-select>

  </div>

  <div *ngIf="value.valueType == 'list'" class="default-option">
    <app-mddb-select class="mr-2"
       [label]="'初期値'"
       [placeholder]="'初期値を選択してください。'"
       [options]="listItemMapWithColumn"
       [ngModel]="value.defaultList"
       (ngModelChange)="onDefaultListChange($event)"
    ></app-mddb-select>
  </div>

  <button type="button" class="close" aria-label="Close" (click)="delete.emit()">
    <mat-icon>close</mat-icon>
  </button>
</div>
