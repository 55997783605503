import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-theme-type-select',
  templateUrl: './theme-type-select.component.html',
  styleUrls: ['./theme-type-select.component.scss']
})
export class ThemeTypeSelectComponent {
  @Input() value: string;
  @Output() readonly valueChange = new EventEmitter<string>();

  readonly themes: { value: string, label: string }[] = [
    { value:  'light_default', label:  'ライト' },
    { value:  'dark_default', label:  'ダーク' },
  ];


  constructor() { }

  ngOnInit() {
  }

  onValueChange(event: string) {
    console.debug(`${this.constructor.name}:onValueChange called.`, event);
    this.value = event;
    this.valueChange.emit(this.value);
  }

}
