import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-map-source-select',
  templateUrl: './map-source-select.component.html',
  styleUrls: ['./map-source-select.component.scss']
})
export class MapSourceSelectComponent {
  @Input() value: string;
  @Output() readonly valueChange = new EventEmitter<string>();

  readonly mapSources: { value: string, label: string }[] = [
    { value:  'osm', label:  'OpenStreetMap' },
    { value:  'chiriin', label:  '地理院地図 標準' },
    { value:  'pale', label:  '地理院地図 淡色' },
    { value:  'blank', label:  '地理院地図 自地図' },
    { value:  'seamless_photo', label:  '地理院地図 写真' },
    { value:  'english', label:  '地理院地図 英語' },
    { value:  'all_rivers', label:  '川だけ' },
    { value:  'transport', label:  '交通' },
    { value:  'cycle', label:  'Cycle' },
    { value:  'water_color', label:  '水彩' },
  ];

  constructor() { }

  ngOnInit() {
  }

  onValueChange(event: string) {
    console.debug(`${this.constructor.name}:onValueChange called.`, event);
    this.value = event;
    this.valueChange.emit(this.value);
  }

}
