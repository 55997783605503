import { Component, OnInit } from '@angular/core';
import { AuthUsecase } from 'src/app/usecase/auth-usecase.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AuthUsecase,
  ) { }

  async ngOnInit() {
    try {
      if (location.href.includes(environment.domain.user)) {
        this.router.navigate(['']);
      } else if (this.auth.isAdminSignedIn) {
        // await this.auth.refreshCredential();
        this.router.navigate(['/app_list']);
      } else {
        this.router.navigate(['/start']);
      }
    } catch (error) {
      console.warn(`${this.constructor.name};:ngOnInit failed.`, error);
      this.router.navigate(['/start']);
    }
  }

}
