import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { StringUtils } from 'src/app/util/string-utils';

// see https://traveler0401.com/angular-error-accessor
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PresetColorFieldComponent),
  multi: true
};

@Component({
  selector: 'app-preset-color-field',
  templateUrl: './preset-color-field.component.html',
  styleUrls: ['./preset-color-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class PresetColorFieldComponent implements OnInit, ControlValueAccessor {
  ngModel: string;

  @Input('ngModel')
  set ngModelSetter(value: string) {
    if (this.presetColors.find(x => x === value) == null) {
      this.ngModel = this.presetColors[0];
      this.ngModelChange.emit(this.ngModel);
    } else {
      this.ngModel = value;
    }
  }


  @Output() readonly ngModelChange = new EventEmitter<string>();
  @Input() label = '';
  @Input() pickerPosition = 'left';
  presetColors = [
    "#DC143C",
    "#FF8C00",
    "#008000",
    "#0000CD",
    "#2E8B57",
    "#FF00FF",
    "#ADFF2F",
    "#9370DB",
  ]

  id = `preset-color-field-${StringUtils.randomString(4)}`;

  constructor() { }

  writeValue(obj: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
  }

  onClick(opt) {
    this.onValueChange(opt);
  }

  onValueChange(event) {
    this.ngModel = event;
    this.ngModelChange.emit(this.ngModel);
  }
}
