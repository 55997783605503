<div class="d-flex  flex-column">

  <app-mddb-select
    class="flex-fill"
    label="地図の種類"
    placeholder="地図の種類を選択"
    [outline]="true"
    [options]="mapSources"
    [isRequired]="true"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)">
  </app-mddb-select>
</div>
