<div class="d-flex  flex-column">
  <app-mddb-select
    class="flex-fill"
    label="初期表示位置"
    placeholder="初期表示位置を選択"
    [outline]="true"
    [options]="types"
    [isRequired]="true"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)">
  </app-mddb-select>
</div>
