    <app-mddb-select
      id="theme-mdb-select"
      label="テーマ"
      placeholder="テーマを選択"
      [isRequired]="true"
      [outline]="false"
      [options]="themes"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)">
    </app-mddb-select>
