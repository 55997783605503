import { Component, OnInit, HostBinding } from '@angular/core';
import { MapSourceType, AppThemeDef } from 'src/app/usecase/sheet-usecase.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as color from 'color';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-map-type-modal',
  templateUrl: './map-type-modal.component.html',
  styleUrls: ['./map-type-modal.component.scss']
})
export class MapTypeModalComponent implements OnInit {

  title: string;
  result: { result: boolean, value?: MapSourceType } = { result: false };
  value: MapSourceType;
  theme: AppThemeDef;

  options = [
    { value:  'osm', label:  'OpenStreetMap' },
    { value:  'chiriin', label:  '地理院地図 標準' },
    { value:  'pale', label:  '地理院地図 淡色' },
    { value:  'blank', label:  '地理院地図 自地図' },
    { value:  'seamless_photo', label:  '地理院地図 写真' },
    { value:  'english', label:  '地理院地図 英語' },
    { value:  'all_rivers', label:  '川だけ' },
    { value:  'transport', label:  '交通' },
    { value:  'cycle', label:  'Cycle' },
    { value:  'water_color', label:  '水彩' },
  ];

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    const getForeColor = (bk) => {
      if (color(bk).luminosity() < 0.5) {
        return '#FFFFFF';
      } else {
        return '#000000';
      }
    }

    return this.sanitizer.bypassSecurityTrustStyle(`
      --theme-background-color: ${this.theme.backgroundColor};
      --theme-foreground-color: ${getForeColor(this.theme.backgroundColor)};
      --theme-splash-background-color: ${this.theme.splashBackgroundColor};
      --theme-splash-foreground-color: ${getForeColor(this.theme.splashBackgroundColor)};
      --theme-button-primary-background-color: ${this.theme.buttonPrimaryBackgroundColor};
      --theme-button-primary-foreground-color: ${getForeColor(this.theme.buttonPrimaryBackgroundColor)};
      --theme-button-secondary-background-color: ${this.theme.buttonSecondaryBackgroundColor};
      --theme-button-secondary-foreground-color: ${getForeColor(this.theme.buttonSecondaryBackgroundColor)};
      --theme-header-background-color: ${this.theme.headerBackgroundColor};
      --theme-header-foreground-color: ${getForeColor(this.theme.headerBackgroundColor)};
      --theme-footer-background-color: ${this.theme.footerBackgroundColor};
      --theme-footer-foreground-color: ${getForeColor(this.theme.footerBackgroundColor)};
    `);
  }


  constructor(
    public activeModal: MatDialogRef<MapTypeModalComponent>,
    private sanitizer: DomSanitizer,
    ) {
  }

  ngOnInit() {
    console.debug(`${this.constructor.name}:ngOnInit`);
  }

  onValueChange(event) {
    this.value = event;
  }

  onYesClick() {
    this.activeModal.close('ok');
  }

  onNoClick() {
    this.activeModal.close();
  }

}
