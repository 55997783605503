<div class="drop-frame rounded d-flex flex-column align-items-center justify-content-center" [id]="'results' + key"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  [class.drop-frame-dragover]="isDragoverOnFaceImageArea"
  [style.border-style]="showFrameAlways ? 'dashed' : 'none'"
>

  <div *ngIf="thumbnail != null" class="image-wrap" (click)="showPreview(0)">

    <img class="preview-image"
      style="margin: 5px; object-fit: contain;"
      [style.width.px]="dropFrame.offsetWidth * thumbnailSizeRatio"
      [style.height.px]="dropFrame.offsetHeight * thumbnailSizeRatio"
      [src]="thumbnail"
    >

    <i *ngIf="!isReadOnly" class="image-close fa fa-times" aria-hidden="true"
      (click)="onDeleteImage($event)"></i>
  </div>

  <label *ngIf="!isReadOnly && thumbnail == null" class="drop-placeholder" [for]="id" style="cursor: pointer;">
    <ng-content></ng-content>
    <input #fl type="file" [id]="id" (change)="onFileChoose(fl.files)"  style="display:none;">
  </label>

</div>


<!-- <ng-image-fullscreen-view
    [images]="imageObject"
    [imageIndex]="selectedImageIndex"
    [show]="showFlag"
    (close)="closeEventHandler()"></ng-image-fullscreen-view> -->
