import { Component, Input } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  template:
    `
<div class="dialog-head">
  <h4 class="title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('dissmiss')">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-body">
  <p>{{message}}</p>
</div>
<div class="dialog-foot">
  <div class="col-12 text-center">
    <button mat-raised-button type="button" class="col-3 mr-3 btn-primary" (click)="activeModal.close('ok')">{{okCaption}}</button>
    <button mat-raised-button type="button" class="col-3 btn-outline-dark" (click)="activeModal.close('cancel')">{{cancelCaption}}</button>
  </div>
</div>
  `
})
// tslint:disable-next-line
export class ConfirmModalContent {
  @Input() title: string;
  @Input() message: string;
  @Input() okCaption = '決定';
  @Input() cancelCaption = 'キャンセル';
  constructor(public activeModal: MatDialogRef<ConfirmModalContent>) { }
}
