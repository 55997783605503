import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MarkerItemDef } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-marker-column-select',
  templateUrl: './marker-column-select.component.html',
  styleUrls: ['./marker-column-select.component.scss']
})
export class MarkerColumnSelectComponent implements OnInit {
  @Input() no = '';
  @Input() placeholder = '';
  @Input() visibleDeleteButton = true;

  @Output() readonly delete = new EventEmitter<void>();

  @Input('columns')
  set columnsSetter(value: string[]) {
    this.options = value.map(x => {
      return { value: x, label: x };
    });
  }
  @Input() value: MarkerItemDef;
  @Output() readonly valueChange = new EventEmitter<MarkerItemDef>();

  @Input()
  reservedSaveCount: {reservedSaveTime: number};

  options: { value: string, label: string }[] = [];

  trueValue: string = '';
  trueValueReadyToEdit: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.value.trueValue !== this.trueValue) this.trueValue = this.value.trueValue;
  }

  onTrueValueDecided(event) {
    if ((event.type !== 'keyup' || event.keyCode == 13) && this.value.trueValue !== this.trueValue) {
      const newValue = this.value;
      newValue.trueValue = this.trueValue;
      this.value = newValue;
      this.valueChange.emit(this.value);
      this.trueValueReadyToEdit = (event.type !== 'blur');
    }
  }

  onTrueValueFocus() {
    this.trueValueReadyToEdit = true;
  }

  onTrueValueEdit() {
    if (this.trueValueReadyToEdit && this.value.trueValue !== this.trueValue) {
      this.trueValueReadyToEdit = false;
      if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
    } else if (!this.trueValueReadyToEdit && this.value.trueValue === this.trueValue) {
      this.trueValueReadyToEdit = true;
      if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
    }
  }

  onColumnChange(event) {
    const newValue = this.value; // Object.assign({}, this.value);
    newValue.column = event;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  /*onTrueValueChange(event) {
    const newValue = this.value; // Object.assign({}, this.value);
    newValue.trueValue = event;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }*/
}
