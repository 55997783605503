import { Component, OnInit, Input, ElementRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AppThemeDef, SheetDef } from 'src/app/usecase/sheet-usecase.service';
import { InfoWindowModalService } from '../../modal/info-window-modal/info-window-modal.service';

@Component({
  selector: 'app-row-summary-panel',
  templateUrl: './row-summary-panel.component.html',
  styleUrls: ['./row-summary-panel.component.scss']
})
export class RowSummaryPanelComponent implements OnInit {

  @Input()
  theme: AppThemeDef;

  @Input()
  selectedRow: any = null;

  @Input()
  distance: any = null;

  @Input()
  sheetDef: SheetDef;

  @Input()
  contentHeight = 0;

  @Input()
  isMobile = false;
  @Input()
  isAndroid = false;

  androidBackProtected = false;

  @Output()
  readonly clearRemainingError = new EventEmitter<void>();

  bottomInfoTitle = '';
  bottomInfoSub1 = '';
  bottomInfoSub2 = '';
  bottomImage = '';

  constructor(
    private infoWindowModal: InfoWindowModalService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedRow'] || changes['sheetDef']) {
      this.updateSelectedRow();
    }
    if (changes['contentHeight']) {
      this.updateContentHeight();
    }
  }

  private updateContentHeight() {
  }

  private updateSelectedRow() {
    console.warn(`${this.constructor.name}:updateSelectedRow.`, this.selectedRow, this.sheetDef);
    if (this.selectedRow != null && this.sheetDef != null) {
      this.bottomInfoTitle =  this.selectedRow[this.sheetDef.infoWindow.titleColumn];
      if (this.sheetDef.infoWindow.sub1Column) {
        this.bottomInfoSub1 =  this.selectedRow[this.sheetDef.infoWindow.sub1Column];
      } else {
        this.bottomInfoSub1 = '';
      }
      if (this.sheetDef.infoWindow.sub2Column) {
        this.bottomInfoSub2 =  this.selectedRow[this.sheetDef.infoWindow.sub2Column];
      } else {
        this.bottomInfoSub2 = '';
      }
      if (this.sheetDef.infoWindow.bottomPanelImage) {
        this.bottomImage = this.selectedRow[this.sheetDef.infoWindow.bottomPanelImage];
      } else {
        this.bottomImage = '';
      }
    }
  }

  onInfoWindowModalShow() {
    this.clearRemainingError.emit();
    const sub1 = {
      title: this.sheetDef.infoWindow.sub1Column,
      value: this.getInfoWindowItemValue(this.sheetDef.infoWindow.sub1Column)
    };
    const sub2 = {
      title: this.sheetDef.infoWindow.sub2Column,
      value: this.getInfoWindowItemValue(this.sheetDef.infoWindow.sub2Column)
    };

    const items = this.sheetDef.infoWindow.items.map(item => {
      return {
        title: item.column,
        value: this.getInfoWindowItemValue(item.column),
        valueType: item.valueType,
      };
    });

    // if (this.isAndroid) {
    //   window.history.pushState({}, '');
    //   this.androidBackProtected = true;
    //   window.addEventListener('popstate', this.onAndroidBack);
    // }
    this.infoWindowModal.show(this.theme, this.bottomInfoTitle, sub1, sub2, items, ()=>this.gotoMapApp(), (str)=>this.gotoPhoneApp(str), (str)=>this.gotoOtherPage(str))
    .then().finally(()=>{
      // if (this.isAndroid && this.androidBackProtected) {
      //   this.androidBackProtected = false;
      //   window.removeEventListener('popstate',this.onAndroidBack);
      //   window.history.go(-1);
      // }
    });
  }

  getInfoWindowItemValue(column: string): string {
    if (this.selectedRow == null) {
      return '';
    }

    return this.selectedRow[column];
  }

  gotoMapApp() {
    const lat = this.selectedRow[this.sheetDef.position.latColumn];
    const lon = this.selectedRow[this.sheetDef.position.lonColumn];
    if (this.isMobile) {
      window.location.href = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
    } else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`, "_blank");
    }
  }

  gotoPhoneApp(phoneNo: string) {
    window.location.href = `tel:${phoneNo}`;
  }
  gotoOtherPage(url: string) {
    window.open(url, "_blank");
  }

  onAndroidBack = () => {
    this.androidBackProtected = false;
    window.removeEventListener('popstate',this.onAndroidBack);
    this.infoWindowModal.close();
  }

  onFailLoadImg(event) {
    event.target.src = '/assets/no_image.png';
  }
}
