import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent {
  @ViewChild('qrcode') qrCode: any;
  shareId: string;

  elementType = 'url';
  userUrl = 'url';

  constructor(
    public activeModal: MatDialogRef<ShareModalComponent>,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.userUrl = `https://${this.shareId}.${environment.domain.user}`;
  }

  onCloseClick() {
    this.activeModal.close();
  }

  onCopy(isUser: boolean){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.userUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toastr.success('URLをコピーしました。', null, {closeButton: true});
  }

  downloadQRCode(){
    const html = this.qrCode.qrcElement.nativeElement.innerHTML;
    const img64: string = html.substr(0, html.length - 2).split('base64,')[1];

    const b64toBlob = (b64Data, type= '', sliceSize= 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type });
    };

    const contentType = 'image/png';
    const blobData = b64toBlob(img64, contentType);

    const filename = 'share_qrcode';

    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
      window.navigator.msSaveOrOpenBlob(blobData, filename);
    } else { // chrome
      const blob = new Blob([blobData], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }

  }
}
