import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { StringUtils } from 'src/app/util/string-utils';

// see https://traveler0401.com/angular-error-accessor
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IconFieldComponent),
  multi: true
};

@Component({
  selector: 'app-icon-field',
  templateUrl: './icon-field.component.html',
  styleUrls: ['./icon-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class IconFieldComponent implements OnInit, ControlValueAccessor {
  @Input() ngModel: string;
  @Output() readonly ngModelChange = new EventEmitter<string>();
  @Input() label = '';

  packs = ['mat'];
  fallbackIcon = 'account_circle';

  id = `icon-field-${StringUtils.randomString(4)}`;

  constructor() { }
  writeValue(obj: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
  }

  onIconPickerSelect(icon: string): void {
    this.ngModel = icon;
    this.ngModelChange.emit(this.ngModel);
  }

}
