<div class="flex-fill">

  <div class="container-fluid">
    <div class="row my-1">

      <div class="col-12 mt-1">
        <img style="width: 42px; height: 42px;" [src]="appImage">
      </div>

      <div class="col-12">
        <div class="text-light mt-2" style="cursor: pointer;"><a (click)="back()">
          <i class="fas fa-chevron-left"></i>
          アプリ一覧
        </a></div>
      </div>

      <div class="col-12 mt-1 text-light">
        <h4 class="app-name">{{app.name}}</h4>
        <hr class="hr-light mb-3"/>
      </div>

      <div class="col-12 text-light">
        <app-sheet-select
          [sheets]="sheets"
          [value]="selectedSheetId"
          (valueChange)="onSelectedSheetIdChange($event)"
          [checked]="selectedSheetEnabled"
          (checkedChange)="onSelectedSheetEnabledChange($event)"
        ></app-sheet-select>
      </div>

      <div class="col-12 d-flex flex-row align-items-center text-light sheet-name">
        <app-text-field
          label="タブ名"
          [(ngModel)]="sheetName"
          (blur)="onSheetNameDecided($event)"
          (keyup)="onSheetNameDecided($event)"
          (focus)="onSheetNameFocus()"
          (ngModelChange)="onSheetNameEdit()"
        ></app-text-field>

        <app-icon-field
          class="ml-3 mb-2"
          label=""
          [(ngModel)]="sheet.icon"
          (ngModelChange)="onDirty(false)"
        ></app-icon-field>

      </div>

      <div class="col-12">
        <app-initial-position-setting
          [(value)]="sheet.initialPosition"
          (valueChange)="onDirty(false)"
          (requestMapPosition)="onRequestMapPosition($event)"
          (initGps)="onInitGps($event)"
          (moveToAllMarker)="onMoveToAllMarker()"
          [onModal]="false"
          [reservedSaveCount]="reservedSaveCount"
        ></app-initial-position-setting>
      </div>

      <div class="col-12">
        <app-map-source-select
          [(value)]="sheet.mapSourceType"
          (valueChange)="onDirty(true, $event)"
        ></app-map-source-select>
      </div>

      <div class="col-12">
        <app-mddb-select
          label="初期表示スタイル"
          [options]="optionShow"
          [(ngModel)]="sheet.defaultShow"
          (ngModelChange)="onDefaultShowListMapChange($event)"
        ></app-mddb-select>
      </div>
    </div>
  </div>

</div>

<div>
  <div class="container-fluid">
    <div class="row my-1">
      <div class="col-12 mt-1">
        <button mat-raised-button class="w-100 btn-panel" (click)="onGotoGoogleDoc()">
          <mat-icon>grid_on</mat-icon>
          データを開く
        </button>
      </div>
      <div class="col-12">
        <button mat-raised-button class="w-100 btn-panel" (click)="onReload()">
          <mat-icon>refresh</mat-icon>
          シート再読み込み
        </button>
      </div>
      <div class="col-12">
        <button mat-raised-button class="w-100 btn-panel" (click)="onShowAppSettingModal()">
          <mat-icon>settings</mat-icon>
          アプリ設定
        </button>
      </div>

      <!--<div class="col-12 mt-1">
        <button class="btn  btn-secondary btn-block" (click)="onShowAppShareModal()">
          <i class="fas fa-share-alt"></i>
          アプリ公開
        </button>
      </div>-->

      <div class="col-12 mt-2">

        <div class="avatar">
          <img [src]="user.photoURL"
            alt="avatar mx-auto white" class="rounded-circle img-fluid"
            style="width: 32px; height: 32px;"/>
        </div>

        <div>{{ user.displayName }} さん</div>

      </div>
    </div>
  </div>
</div>
