<div class="d-flex flex-column">
  <app-mddb-select
    class="flex-fill"
    label="シート選択"
    placeholder="シートを選択"
    [isRequired]="true"
    [outline]="true"
    [options]="options"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)">
  </app-mddb-select>

  <app-check-box class="ml-2"
    label="使用する"
    [ngModel]="checked"
    (ngModelChange)="onCheckedChange($event)"
  ></app-check-box>
</div>
