<div>
<div class="d-flex align-items-center flex-wrap align-content-end">

  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">
      <i class="mr-3 mb-2 fas fa-compress-arrows-alt" style="font-size: 1.5rem;" (click)="onApplyMapPosition()"></i>
      <app-text-field
        label="緯度"
        type="number"
        [(ngModel)]="latLocal"
        (blur)="onLatLngDecided($event)"
        (keyup)="onLatLngDecided($event)"
        (focus)="onLatLngFocus('lat')"
        (ngModelChange)="onLatLngEdit('lat')"
        [contentError]="latProblem>1&&!onModal"
        [contentWarning]="latProblem===1&&!onModal"
      ></app-text-field>
      <app-text-field
        class="ml-4"
        label="経度"
        type="number"
        [(ngModel)]="lngLocal"
        (blur)="onLatLngDecided($event)"
        (keyup)="onLatLngDecided($event)"
        (focus)="onLatLngFocus('lng')"
        (ngModelChange)="onLatLngEdit('lng')"
        [contentError]="lngProblem>1&&!onModal"
        [contentWarning]="lngProblem===1&&!onModal"
      ></app-text-field>
    </div>
  </div>

  <app-zoom-level-select
    [onModal]="onModal"
    [(value)]="zoomLevel"
    (valueChange)="onZoomLevelChange($event)"
  ></app-zoom-level-select>

</div>

<div class="field-label" style="color: #f00;" *ngIf="onModal&&((latProblem===3)||(lngProblem===3))">
  半角数字、半角ピリオド (".")以外の文字は使用できません
</div>
<div class="field-label" style="color: #f00;" *ngIf="onModal&&((latProblem<3)&&(lngProblem<3)&&((latProblem===2)||(lngProblem===2)))">
  緯度および経度は空欄にすることはできません
</div>
<div class="field-label" style="color: #000;" *ngIf="onModal&&((latProblem<2)&&(lngProblem<2)&&((latProblem===1)||(lngProblem===1)))">
  日本国外を指定すると、地図の種類によっては表示できない場合があります
</div>
</div>
